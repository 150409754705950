import { find, range } from "lodash";
import { DateTime, Duration, DurationLikeObject } from "luxon";
import i18n from "@/plugins/i18n";
import { isBlank } from "./string";
import { DateTimeParts, MonthDayTime, TimeSeries } from "@/types";

export const US_TIME_ZONES = [
  { text: "Samoa Standard Time", value: -11 },
  { text: "Hawaii-Aleutian Standard Time", value: -10 },
  { text: "Alaska Standard Time", value: -9 },
  { text: "Pacific Standard Time", value: -8 },
  { text: "Mountain Standard Time", value: -7 },
  { text: "Central Standard Time", value: -6 },
  { text: "Eastern Standard Time", value: -5 }
];

export function timeZoneOffsetInMinutes(time: number, timeZone: string): number {
  return DateTime.fromMillis(time, { zone: timeZone }).offset;
}

export function formatDuration(duration: Duration, units: (keyof DurationLikeObject)[]): string {
  let newDuration = duration.shiftTo(...units);
  if (newDuration.toMillis() < 0) newDuration = newDuration.negate();

  // Find the first unit with a corresponding non-zero value
  const firstUnit = find(units, u => newDuration.get(u) > 0) || units[units.length - 1];
  newDuration = Duration.fromObject({ [firstUnit]: Math.round(newDuration.get(firstUnit)) });

  const humanDuration = newDuration.toHuman({ unitDisplay: "short" });
  return i18n.t("general.time_ago", { duration: humanDuration }).toString();
}

export function formatMonthDayTime(monthDayTime: MonthDayTime): string | null {
  const { month, day, time } = monthDayTime;
  if (month === null || day === null || isBlank(time)) return null;

  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");

  // Treat date like it's in 2000 (a leap year)
  const date = DateTime.fromISO(`2023-${monthStr}-${dayStr}T${time}`, { zone: "utc" });
  return date.toISO().replace(/\.000Z/, "");
}

export function formatDateTimeParts(parts: DateTimeParts): string {
  const { date, time } = parts;
  const dateStr = `${date.toISODate()}T${time}`;
  const fullDate = DateTime.fromISO(dateStr, { zone: "utc" });
  return fullDate.toISO().replace(/\.000Z/, "");
}

export function formatTimeStrAsAmPm(time: string): string {
  const [hour, minute] = parseTime(time);
  const dateTime = DateTime.fromObject({ hour, minute });
  if (!dateTime.isValid) return "";
  return dateTime.toFormat("h:mm a");
}

export function monthNames(): string[] {
  const startDate = DateTime.utc(2024, 1, 1).setLocale(i18n.locale);
  return range(12).map(i => startDate.plus({ months: i }).toLocaleString({ month: "long" }));
}

export function dayNames(): string[] {
  // Start on a Sunday
  const startDate = DateTime.utc(2024, 1, 7).setLocale(i18n.locale);
  return range(7).map(i => startDate.plus({ days: i }).toLocaleString({ weekday: "long" }));
}

export function compareTimes(a: string, b: string): number {
  const [aHours, aMins] = parseTime(a);
  const [bHours, bMins] = parseTime(b);
  if (aHours === bHours) {
    return aMins - bMins;
  } else {
    return aHours - bHours;
  }
}

export function parseTime(timeStr: string): [number, number] {
  const [hours, mins] = timeStr.split(":");
  return [parseInt(hours), parseInt(mins)];
}

export function dailyGraphDayFormat(date: DateTime, compact: boolean): string {
  const monthDay = date.toFormat("LLL d");

  if (compact) return monthDay;
  return `${date.weekdayShort}<br><b>${monthDay}</b>`;
}

export function adjustTimestampToTimeZone(timestamp: number, timeZone: string): number {
  const offset = timeZoneOffsetInMinutes(timestamp as number, timeZone) * 60000;
  return timestamp + offset;
}

export function adjustTimestampFromTimeZone(timestamp: number, timeZone: string): number {
  const offset = timeZoneOffsetInMinutes(timestamp as number, timeZone) * 60000;
  return timestamp - offset;
}

export function adjustSeriesTimestamps(series: TimeSeries, timeZone: string): TimeSeries {
  return series.map(([timestamp, value]) => [adjustTimestampToTimeZone(timestamp, timeZone), value]);
}

export function parseTimestamp(timestamp: string | null): DateTime | null {
  return timestamp ? DateTime.fromISO(timestamp, { zone: "utc", setZone: true }) : null;
}
