import gql from "graphql-tag";
import features from "@/config/features";

const stateChangeEnabled = gql`
  fragment StateChangeFields on AssetDataChange {
    deviceLock
    updateStamp
    state
    pending
    stateInfo
  }
`;

const stateChangeDisabled = gql`
  fragment StateChangeFields on AssetDataChange {
    __typename
  }
`;

export const stateChange = features.stateManagement ? stateChangeEnabled : stateChangeDisabled;
