import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from "apollo-upload-client";
import { getInstance } from "@/plugins/auth0";

async function getAuthorizationHeader(): Promise<string> {
  const token = await getInstance().getAccessTokenSilently();
  return `Bearer ${token}`;
}

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS || "",
  options: {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: Infinity,
    async connectionParams() {
      return { Authorization: await getAuthorizationHeader() };
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP
});

// Create auth for ws and http links
const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      "access-control-allow-origin": process.env.VUE_APP_GRAPHQL_HTTP,
      Authorization: await getAuthorizationHeader()
    }
  };
});

const link = split(
  // Use ws link for subscriptions, and http link for everything else
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache,
  connectToDevTools: true
});

if (process.env.NODE_ENV?.trim() !== "test") {
  Vue.use(VueApollo, { apolloClient });
}

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $skipAll() {
      return !getInstance().isAuthenticated;
    }
  }
});
