import { ref, Ref, unref, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { findLastIndex } from "lodash";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { transformSeriesData } from "@/utils/number";
import { GqlTimeSeries, MaybeRef, TimeSeries, Unit } from "@/types";
import { getPropertyConfig } from "@/config/asset";
import energyConfig from "@/energy-pro/config/base-energy-sensor";

const DURATION_PERIOD = [
  { days: 0, period: 60 },
  { days: 1, period: 60 * 15 },
  { days: 3, period: 60 * 30 },
  { days: 7, period: 60 * 60 },
  { days: 14, period: 60 * 60 * 6 }
];

export interface UseAverageCurrentTrendOptions {
  enabled?: MaybeRef<boolean>;
  period?: MaybeRef<number>;
  unit?: MaybeRef<Unit>;
}

export interface UseAverageCurrentTrendResult {
  series: Ref<TimeSeries | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAverageCurrentTrend(
  assetUuids: MaybeRef<string[]>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { enabled = true, period, unit = "kw" }: UseAverageCurrentTrendOptions = {}
): UseAverageCurrentTrendResult {
  const seriesData = ref<GqlTimeSeries>();
  const loadingError = ref(false);

  const effectivePeriod = computed(() => {
    const passedPeriod = unref(period);
    if (passedPeriod) return passedPeriod;

    const days = unref(endDate).diff(unref(startDate), "days").days;
    const index = findLastIndex(DURATION_PERIOD, g => days >= g.days);
    return DURATION_PERIOD[index].period;
  });

  const queryVariables = computed(() => {
    return {
      deviceUuids: unref(assetUuids),
      startDate: unref(startDate).setZone("utc"),
      endDate: unref(endDate).setZone("utc"),
      period: effectivePeriod.value
    };
  });

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AverageCurrentTrend($deviceUuids: [ID!]!, $startDate: DateTime!, $endDate: DateTime!, $period: Int!) {
        seriesData: averageCurrentTrend(
          deviceUuids: $deviceUuids
          startDate: $startDate
          endDate: $endDate
          period: $period
        )
      }
    `,
    queryVariables,
    () => ({
      enabled: unref(enabled)
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      seriesData.value = queryResult.data.seriesData;
    }
  });

  const series = computed(() => {
    if (!seriesData.value) return undefined;

    const propertyConfig = getPropertyConfig(energyConfig, "average_energy", unref(unit));
    return transformSeriesData(seriesData.value, "average_energy", propertyConfig.convertValueFn);
  });

  return { series, loadingError, refetch, loading };
}
