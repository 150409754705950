import { getCurrentInstance, reactive, Ref, toRefs, watch } from "vue";
import { assign } from "lodash";

interface VuetifyDisplay {
  xs: Ref<boolean>;
  sm: Ref<boolean>;
  md: Ref<boolean>;
  lg: Ref<boolean>;
  xl: Ref<boolean>;
  smAndUp: Ref<boolean>;
  mdAndUp: Ref<boolean>;
  lgAndUp: Ref<boolean>;
  smAndDown: Ref<boolean>;
  mdAndDown: Ref<boolean>;
  lgAndDown: Ref<boolean>;
}

export function useDisplay(): VuetifyDisplay {
  const vm = getCurrentInstance();
  const breakpoint = vm?.proxy?.$vuetify?.breakpoint || undefined;

  function extractBreakpointProps() {
    return {
      xs: breakpoint?.xs ?? false,
      sm: breakpoint?.sm ?? false,
      md: breakpoint?.md ?? false,
      lg: breakpoint?.lg ?? false,
      xl: breakpoint?.xl ?? false,
      smAndUp: breakpoint?.smAndUp ?? false,
      mdAndUp: breakpoint?.mdAndUp ?? false,
      lgAndUp: breakpoint?.lgAndUp ?? false,
      smAndDown: breakpoint?.smAndDown ?? false,
      mdAndDown: breakpoint?.mdAndDown ?? false,
      lgAndDown: breakpoint?.lgAndDown ?? false
    };
  }

  const state = reactive(extractBreakpointProps());

  // When breakpoint values change, update reactive state
  watch(
    () => breakpoint,
    () => {
      assign(state, extractBreakpointProps());
    },
    { deep: true }
  );

  return toRefs(state);
}
