import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { FloorplanFields } from "@/types";

export default function (floorUuid: string, floorplan: FloorplanFields): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($floorUuid: ID!, $floorplan: Upload!, $sizeX: Int!, $sizeY: Int!) {
          uploadFloorplan(floorUuid: $floorUuid, floorplan: $floorplan, sizeX: $sizeX, sizeY: $sizeY) {
            statusCode
          }
        }
      `,
      variables: {
        floorUuid,
        floorplan: floorplan.image,
        sizeX: floorplan.sizeX,
        sizeY: floorplan.sizeY
      }
    })
    .then(response => {
      return response.data.uploadFloorplan;
    });
}
