import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { BuildingFields } from "@/types";

export default function (buildingUuid: string, parameters: BuildingFields): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($buildingUuid: ID!, $parameters: UpdateBuildingConfig!) {
          updateBuilding(buildingUuid: $buildingUuid, parameters: $parameters)
        }
      `,
      variables: {
        buildingUuid: buildingUuid,
        parameters: parameters
      }
    })
    .then(response => {
      return response.data.updateBuilding;
    });
}
