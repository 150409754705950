// Given a full name it will return the first letter of the first and last name
// If more than 2 names it takes the first letter of the first and last
// If only one name exists it returns the first letter
export function initialsFromFullName(name: string): string {
  const allNames = name.trim().split(" ");
  return allNames.reduce((initials: string, name: string, index: number) => {
    // only add first and last
    if (index === 0 || index === allNames.length - 1) {
      initials = `${initials}${name.charAt(0).toUpperCase()}`;
    }
    return initials;
  }, "");
}
