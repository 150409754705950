import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (uuid: string, name: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($uuid: ID!, $name: String!) {
          renameDocument(uuid: $uuid, name: $name) {
            message
            statusCode
          }
        }
      `,
      variables: {
        uuid: uuid,
        name: name
      }
    })
    .then(response => {
      return response.data.renameDocument;
    });
}
