import { extend } from "vee-validate";
import {
  ValidationRuleSchema,
  ValidationRuleFunction,
  ValidationMessageGenerator
} from "vee-validate/dist/types/types";
import i18n, { tFallback } from "@/plugins/i18n";
import { formatTimeStrAsAmPm } from "@/utils/date";
import { fromPairs, isFunction, toPairs } from "lodash";

type FormatterFn = (value: any) => string;

interface ExtendedValidationRuleSchema extends ValidationRuleSchema {
  messageKey?: string;
  formatValue?: FormatterFn;
  formatParams?: Record<string, FormatterFn>;
}

type ExtendedValidationRule = ValidationRuleFunction | ExtendedValidationRuleSchema;

export function installRule(name: string, schema: ExtendedValidationRule): void {
  if (isFunction(schema)) {
    extend(name, schema);
  } else {
    extend(name, {
      ...schema,
      message: schema.message ?? buildMessageGenerator(schema)
    });
  }
}

function buildMessageGenerator(schema: ExtendedValidationRuleSchema): ValidationMessageGenerator {
  const generator: ValidationMessageGenerator = (field, values) => {
    const newValues = {
      ...formatValues(values, schema),
      _rule_: schema.messageKey ?? values._rule_
    };
    return lookupRuleMessage(field, newValues);
  };

  return generator;
}

function formatValues(values: Record<string, any>, schema: ExtendedValidationRuleSchema): Record<string, any> {
  const paramFormatters = toPairs(schema.formatParams ?? {});
  const paramPairs = paramFormatters.map(([k, fn]) => [k, formatValue(values[k], fn)]);

  return {
    ...values,
    _value_: formatValue(values._value_, schema.formatValue),
    ...fromPairs(paramPairs)
  };
}

function formatValue(value: any, formatter: FormatterFn | undefined): any {
  return formatter ? formatter(value) : value;
}

export function lookupRuleMessage(field: string, values: Record<string, any>): string {
  const strippedField = field.replace(/(\[\d+\])+/, "");
  values._field_ = i18n.t(`fields.${strippedField}.label`);

  const rule: string = values._rule_;
  const keys = [`fields.${strippedField}.${rule}`, `validations.messages.${rule}`];
  return tFallback(keys, values).toString();
}

export function convertTimesToAmPm(values: Record<string, any>, keys: string[]): Record<string, any> {
  keys.forEach(key => {
    values[key] = formatTimeStrAsAmPm(values[key]);
  });

  return values;
}
