import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (commentUuid: string, contents: string): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($commentUuid: ID!, $comment: String!) {
        updateComment(commentUuid: $commentUuid, comment: $comment)
      }
    `,
    variables: {
      commentUuid: commentUuid,
      comment: contents
    }
  });
}
