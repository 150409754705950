import { Ref, ref } from "vue";
import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import { useLazyQuery } from "@vue/apollo-composable";
import { PageInfo, Comment } from "@/types";
import deleteCommentMutation from "@/gql/delete-comment-mutation";
import updateCommentMutation from "@/gql/update-comment-mutation";

export interface UseCommentsResult {
  createComment: (contents: string) => Promise<any>;
  createCommentLoading: Ref<boolean>;
  comments: Ref<Comment[]>;
  loadComments: () => Promise<any> | undefined;
  commentsLoading: Ref<boolean>;
  pageInfo: Ref<PageInfo>;
  deleteComment: (comment: Comment) => Promise<any>;
  updateComment: (comment: Comment, contents: string) => Promise<any>;
}

export function useComments(
  parent: "building" | "account" | "asset" | "event_history",
  parentUuid: string
): UseCommentsResult {
  const tableName = `ubx_${parent}`;
  const parentKey = `${parent}_uuid`;

  // We need to clear the cache of comments when we create a new one
  const cacheClearer = (proxy: any) =>
    Object.keys(proxy.data.data).forEach(key => {
      if (key.match(/resourceComment/)) {
        proxy.data.delete(key);
      }
    });

  const { mutate: createCommentMutation, loading: createCommentLoading } = useMutation(
    gql`
      mutation ($parentTable: String!, $parentKey: String!, $parentUuid: ID!, $comment: String!) {
        createComment(parentTable: $parentTable, parentKey: $parentKey, parentUuid: $parentUuid, comment: $comment)
      }
    `,
    {
      variables: {
        parentTable: tableName,
        parentKey: parentKey,
        parentUuid: parentUuid,
        comment: ""
      },
      update: cacheClearer
    }
  );

  function createComment(contents: string): Promise<any> {
    return createCommentMutation({
      comment: contents,
      parentTable: tableName,
      parentKey: parentKey,
      parentUuid: parentUuid
    });
  }

  const comments: Ref<Comment[]> = ref([]);
  const pageInfo: Ref<PageInfo> = ref({ totalCount: 0, pageCount: 0, limit: 0, offset: 0 });
  const {
    load,
    refetch,
    onResult,
    loading: commentsLoading
  } = useLazyQuery(
    gql`
      query ResourceComments($resourceTable: String!, $resourceKey: String!, $resourceUuid: ID!) {
        commentPage: resourceComments(
          resourceTable: $resourceTable
          resourceKey: $resourceKey
          resourceUuid: $resourceUuid
        ) {
          data {
            commentUuid
            comment
            stamp
            updated
            user {
              emailAddress
              contactInfo {
                emailAddress
                name
              }
            }
          }
          pageInfo {
            totalCount
            pageCount
            limit
            offset
          }
        }
      }
    `,
    {
      resourceTable: tableName,
      resourceKey: parentKey,
      resourceUuid: parentUuid
    }
  );
  const loadComments = (): Promise<any> | undefined => {
    return load() || refetch();
  };
  onResult(queryResult => {
    if (queryResult.data) {
      comments.value = queryResult.data.commentPage.data;
      pageInfo.value = queryResult.data.commentPage.pageInfo;
    }
  });

  function deleteComment(comment: Comment): Promise<any> {
    return deleteCommentMutation(comment.commentUuid);
  }

  function updateComment(comment: Comment, contents: string): Promise<any> {
    return updateCommentMutation(comment.commentUuid, contents);
  }

  return {
    createComment,
    createCommentLoading,
    comments,
    loadComments,
    commentsLoading,
    pageInfo,
    deleteComment,
    updateComment
  };
}
