import { DateTime } from "luxon";
import { fromPairs, omit } from "lodash";
import { DateTimeParts, FieldConfig, FieldDescriptorObject, FieldMappingType, MonthDayTime } from "@/types";
import { isBlank } from "./string";
import store from "@/store";
import { readFieldConfig } from "@/config/form";

interface FieldMappingConfig<T = any, U = any[]> {
  fieldConfigs: Partial<FieldConfig>[];
  toValues: (value: T) => U;
}

const MAPPING_CONFIGS: Record<FieldMappingType, FieldMappingConfig> = {
  datetime_to_month_day_time: {
    fieldConfigs: [
      {
        dataType: "month_day"
      },
      {
        dataType: "time"
      }
    ],
    toValues: value => {
      const monthDayTime = parseMonthDayTime(value, store.getters.timeZone);
      return [[monthDayTime?.month, monthDayTime?.day], monthDayTime?.time];
    }
  },
  datetime_to_date_time: {
    fieldConfigs: [
      {
        dataType: "date"
      },
      {
        dataType: "time"
      }
    ],
    toValues: value => {
      const dateTime = splitDateTime(value);
      return [dateTime?.date, dateTime?.time];
    }
  }
};

export function buildMappedFieldConfigs(fieldConfig: FieldConfig): Record<string, FieldConfig> {
  const strippedConfig = omit(fieldConfig, ["displayType"]);
  if (!strippedConfig.mapsToFields) return {};

  const { mappingType, fieldNames: mappedNames } = strippedConfig.mapsToFields;
  const pairs = mappedNames.map((mappedName, fieldIndex) => {
    const mappingFieldConfig = MAPPING_CONFIGS[mappingType].fieldConfigs[fieldIndex];
    const completeFieldConfig = readFieldConfig({
      ...strippedConfig,
      ...mappingFieldConfig,
      ...strippedConfig.mappedfields[mappedName]
    });
    return [mappedName, completeFieldConfig];
  });

  return fromPairs(pairs);
}

export function getMappedFieldDescriptors(
  fieldConfig: FieldConfig,
  descriptor: FieldDescriptorObject
): FieldDescriptorObject[] {
  if (!fieldConfig.mapsToFields) return [];

  const { fieldNames } = fieldConfig.mapsToFields;
  return fieldNames.map(name => ({
    ...descriptor,
    name
  }));
}

export function expandDescriptorToMappings(
  fieldConfig: FieldConfig,
  descriptor: FieldDescriptorObject
): FieldDescriptorObject[] {
  return [descriptor, ...getMappedFieldDescriptors(fieldConfig, descriptor)];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toMappedValues(mappingType: FieldMappingType, value: any): any[] {
  return MAPPING_CONFIGS[mappingType].toValues(value);
}

function parseMonthDayTime(datetimeStr: string, timeZone: string): MonthDayTime | null {
  if (isBlank(datetimeStr)) return null;

  const date = DateTime.fromISO(datetimeStr, { zone: timeZone, setZone: true });
  return {
    month: date.month,
    day: date.day,
    time: date.toFormat("HH:mm")
  };
}

function splitDateTime(datetimeStr: string): DateTimeParts | null {
  if (isBlank(datetimeStr)) return null;

  const date = DateTime.fromISO(datetimeStr, { zone: "utc" });
  return {
    date: date,
    time: date.toFormat("HH:mm:ss")
  };
}
