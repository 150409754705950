import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { MaybeRef } from "@/types";

export interface UseAccountModelsOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseAccountModelsResult {
  models: Ref<Record<string, any>[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAccountModels(
  accountUuid: MaybeRef<string | null>,
  { enabled = true }: UseAccountModelsOptions = {}
): UseAccountModelsResult {
  const models = ref<Record<string, any>[]>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query Models($accountUuid: ID!) {
        models: assetAccountModels(accountUuid: $accountUuid) {
          name
          assetModelUuid
        }
      }
    `,
    {
      accountUuid
    },
    () => ({
      enabled: unref(enabled) && unref(accountUuid) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      models.value = queryResult.data.models;
    }
  });

  return { models, loadingError, refetch, loading };
}
