import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (commentUuid: string): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($commentUuid: ID!) {
        deleteComment(commentUuid: $commentUuid)
      }
    `,
    variables: {
      commentUuid: commentUuid
    }
  });
}
