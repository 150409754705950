import { computed, ComputedRef, unref } from "vue";
import { isNil } from "lodash";
import VueI18n from "vue-i18n";
import i18n from "@/plugins/i18n";
import { formatNumber } from "@/utils/number";
import { DecoratedAsset, DecoratedProperty, PropertyConfig, Unit, MaybeRef } from "@/types";
import usePropertyWithUnit from "./use-property-with-unit";
import { propertyName } from "@/utils/models";

export interface UseBasicPropertyInfoOptions {
  unit?: MaybeRef<Unit | undefined>;
  labelKey?: string;
  labelValues?: Record<string, any>;
}

export interface UseBasicPropertyInfoResult {
  effectiveProperty: ComputedRef<DecoratedProperty>;
  propertyConfig: ComputedRef<PropertyConfig>;
  label: ComputedRef<string>;
  value: ComputedRef<string | null>;
  isValueNil: ComputedRef<boolean>;
  unit: ComputedRef<VueI18n.TranslateResult | undefined>;
}

export default function useBasicPropertyInfo(
  asset: MaybeRef<DecoratedAsset>,
  property: MaybeRef<DecoratedProperty>,
  options: UseBasicPropertyInfoOptions = {}
): UseBasicPropertyInfoResult {
  const { unit, labelKey, labelValues } = options;

  const { effectiveProperty, propertyConfig } = usePropertyWithUnit(asset, property, unit);

  const label = computed(() => {
    return propertyName(unref(asset), effectiveProperty.value.name, labelKey, labelValues ?? {});
  });

  const isValueNil = computed(() => {
    return isNil(effectiveProperty.value.value);
  });

  const value = computed(() => {
    return formatNumber(effectiveProperty.value.value, { format: propertyConfig.value.format });
  });

  const unitStr = computed(() => {
    const unit = propertyConfig.value.unit;
    const showUnit = unit && !propertyConfig.value.hideValueUnit;
    return showUnit ? i18n.t(`units.${unit}`) : undefined;
  });

  return { effectiveProperty, propertyConfig, label, value, isValueNil, unit: unitStr };
}
