import { computed, ComputedRef, unref } from "vue";
import { convertProperty } from "@/config/asset";
import { DecoratedAsset, DecoratedProperty, PropertyConfig, Unit, MaybeRef } from "@/types";

export interface UsePropertyWithUnitResult {
  effectiveProperty: ComputedRef<DecoratedProperty>;
  propertyConfig: ComputedRef<PropertyConfig>;
}

export default function usePropertyWithUnit(
  asset: MaybeRef<DecoratedAsset>,
  property: MaybeRef<DecoratedProperty>,
  unit?: MaybeRef<Unit | undefined>
): UsePropertyWithUnitResult {
  const effectiveProperty = computed(() => {
    return convertProperty(unref(asset), unref(property), unref(unit));
  });

  const propertyConfig = computed(() => {
    return effectiveProperty.value.config;
  });

  return { effectiveProperty, propertyConfig };
}
