import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { FloorFields } from "@/types";

interface UpdateFloorConfig extends FloorFields {
  position?: number;
}

export default function (floorUuid: string, parameters: UpdateFloorConfig): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($floorUuid: ID!, $parameters: UpdateFloorConfig!) {
          updateFloor(floorUuid: $floorUuid, parameters: $parameters) {
            statusCode
            message
          }
        }
      `,
      variables: {
        floorUuid,
        parameters
      }
    })
    .then(response => {
      return response.data.updateFloor;
    });
}
