import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

export default function (name: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $name: String!) {
          createBuildingGroup(accountUuid: $accountUuid, name: $name) {
            buildingGroupUuid
          }
        }
      `,
      variables: {
        accountUuid: store.getters.accountUuid,
        name: name
      }
    })
    .then(response => {
      return response.data.createBuildingGroup;
    });
}
