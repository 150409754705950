import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import auth from "./auth";
import loading from "./loading";
import snackbar from "./snackbar";
import dragDrop from "./drag-drop";
import nav from "./nav";
import floorplan from "./floorplan";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ["auth.accountUuid"]
});

export default new Vuex.Store({
  modules: {
    auth,
    loading,
    snackbar,
    dragDrop,
    nav,
    floorplan
  },
  plugins: [dataState],
  state,
  getters,
  mutations,
  actions
});
