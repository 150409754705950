import { computed, ComputedRef, unref } from "vue";
import { isArray, isEmpty, isNil, isString, trim } from "lodash";
import { DateTime, Duration } from "luxon";
import usePropertyTimestamps, { UsePropertyTimestampsResult } from "@/composables/use-property-timestamps";
import { Field, MaybeRef, FieldUpdateInfo } from "@/types";
import i18n from "@/plugins/i18n";
import store from "@/store";
import { formatNumber } from "@/utils/number";
import { formatTimeStrAsAmPm } from "@/utils/date";

const RECENT_STATUS_DURATION = Duration.fromDurationLike({ seconds: -30 });

export interface UseFieldOptions {
  loading?: MaybeRef<boolean>;
  i18nValues?: Record<string, any>;
}

export interface UseFieldResult extends UsePropertyTimestampsResult {
  updateInfo: ComputedRef<FieldUpdateInfo>;
  formattedValue: ComputedRef<string>;
  formattedNewValue: ComputedRef<string>;
}

export default function useField(
  field: MaybeRef<Field>,
  { loading = false, i18nValues = {} }: UseFieldOptions = {}
): UseFieldResult {
  const timestamps = usePropertyTimestamps(field);
  const missingValue = i18n.t("state_management.empty_value").toString();

  const updateInfo = computed<FieldUpdateInfo>(() => {
    const { relativeTimestamp } = timestamps;
    const { updateInfo: currentUpdateInfo } = unref(field);

    if (unref(loading)) return { status: "pending", message: i18n.t("messages.loading").toString() };

    if (currentUpdateInfo.status === "success" && relativeTimestamp.value) {
      const recentStatus = relativeTimestamp.value > RECENT_STATUS_DURATION;
      if (!recentStatus) return { ...currentUpdateInfo, status: "normal" };
    }

    return currentUpdateInfo;
  });

  function translation(key: string, checkExistence = false): string | null {
    const namespace = unref(field).config.i18nNamespace;
    const fullKey = `fields.${namespace}.${unref(field).name}.${key}`;
    if (checkExistence && !i18n.te(fullKey)) return null;
    return i18n.t(fullKey, unref(i18nValues)).toString();
  }

  function lookupOption(value: string | string[]): string {
    if (isArray(value)) return value.map(v => lookupOption(v)).join(", ");

    if (isValueEmpty(value)) return missingValue;
    return translation(`options.${value}`, true) ?? value;
  }

  function formatValue(value: any): string {
    const fieldConfig = unref(field).config;
    const { dataType, displayType, enum: isEnum } = fieldConfig;
    let newValue: any = value;

    if (isValueEmpty(value)) return missingValue;

    switch (dataType) {
      case "string":
        if (displayType === "select" || isEnum) {
          newValue = lookupOption(value);
        }
        break;
      case "number":
        newValue = formatNumber(value, { format: fieldConfig.format });
        break;
      case "boolean":
        newValue = i18n.t(`statuses.${value ? "enabled" : "disabled"}`);
        break;
      case "date":
        newValue = value?.setZone(store.getters.timeZone)?.toISODate();
        break;
      case "month_day":
        if (isArray(value) && value.length === 2) {
          newValue = DateTime.fromObject({ year: 2024, month: value[0], day: value[1] }).toFormat("LLL dd");
        }
        break;
      case "time":
        newValue = formatTimeStrAsAmPm(value);
        break;
    }

    return isValueEmpty(newValue) ? missingValue : newValue.toString();
  }

  const formattedValue = computed(() => {
    return formatValue(unref(field).originalValue);
  });

  const formattedNewValue = computed(() => {
    return formatValue(updateInfo.value.newValue);
  });

  return {
    updateInfo,
    formattedValue,
    formattedNewValue,
    ...timestamps
  };
}

function isValueEmpty(value: any): boolean {
  if (isArray(value)) return isEmpty(value);
  if (isString(value)) return isEmpty(trim(value));
  return isNil(value);
}
