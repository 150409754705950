const state = {
  loading: 0,
  loadingError: false
};

const getters = {
  isLoading(state: Record<any, any>): boolean {
    return state.loading > 0;
  },

  hasLoadingError(state: Record<any, any>): boolean {
    return state.loadingError;
  }
};

const mutations = {
  startLoading(state: Record<any, any>): void {
    state.loading++;
  },

  finishLoading(state: Record<any, any>): void {
    state.loading--;
    state.loadingError = false;
  },

  finishLoadingWithError(state: Record<any, any>): void {
    state.loading--;
    state.loadingError = true;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
