const state = {
  dragOffset: null
};

const mutations = {
  setDragOffset(state: Record<any, any>, offset: [number, number]): void {
    state.dragOffset = offset;
  }
};

export default {
  state,
  mutations
};
