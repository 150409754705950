export default {
  isImagePreloaded(state: Record<any, any>) {
    return (url: string): boolean => {
      return state.preloadedImages[url];
    };
  },

  timeZone(state: Record<any, any>): string {
    return state.contextualTimeZone ?? "utc";
  }
};
