interface SelectAssetOptions {
  assetUuid: string;
  onFloorplan: boolean;
}

const state = {
  selectedAssetUuid: null,
  selectedAssetOnFloorplan: false
};

const mutations = {
  selectAsset(state: Record<any, any>, { assetUuid, onFloorplan }: SelectAssetOptions): void {
    state.selectedAssetUuid = assetUuid;
    state.selectedAssetOnFloorplan = onFloorplan;
  },

  deselectAsset(state: Record<any, any>): void {
    state.selectedAssetUuid = null;
    state.selectedAssetOnFloorplan = false;
  }
};

export default {
  state,
  mutations
};
