import { FormConfig } from "@/types";
import { readFormConfig } from "./form";

export const formConfig: FormConfig = readFormConfig({
  i18nNamespace: "floor",
  fields: {
    name: {
      dataType: "string",
      rules: { required: true }
    }
  }
});
