import { Ref, computed } from "vue";
import { DecoratedAsset, GqlAsset } from "@/types";
import { decorateAsset, getProperty } from "@/config/asset";
import useDecoratedProperty from "@/composables/use-decorated-property";

export interface UseAssetPropertyResult {
  decoratedAsset: Ref<DecoratedAsset | undefined>;
  label: Ref<string>;
  unit: Ref<string>;
  convertAndRound: (v: number) => number | null;
}

export function useAssetProperty(asset: GqlAsset, property: string): UseAssetPropertyResult {
  const decoratedAsset = computed(() => decorateAsset(asset));

  const {
    label: labelStr,
    propertyConfig,
    unit: unitStr
  } = useDecoratedProperty(decoratedAsset.value, getProperty(decoratedAsset.value, property));

  const label = computed(() => labelStr.value as string);
  const unit = computed(() => (unitStr.value as string) || "");

  const converter = computed(() =>
    propertyConfig.value.convertValueFn ? propertyConfig.value.convertValueFn : (v: number | null) => v
  );

  function convertAndRound(v: number): number | null {
    const val = converter.value(v);
    return val ? Math.round(val) : null;
  }

  return { decoratedAsset, label, unit, convertAndRound };
}
