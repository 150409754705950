import { getInstance } from "@/plugins/auth0";
import { find, orderBy } from "lodash";
import { GqlAccount } from "@/types";

const state = {
  accountUuid: null,
  accounts: [],
  accessToken: null
};

const getters = {
  // eslint-disable-next-line
  isLoggedIn(state: Record<any, any>): boolean {
    return getInstance().isAuthenticated;
  },

  // eslint-disable-next-line
  user(state: Record<any, any>): Record<any, any> {
    return getInstance().user;
  },

  accountUuid(state: Record<any, any>): string | null {
    return state.accountUuid;
  },

  account(state: Record<any, any>): GqlAccount | null {
    if (!state.accountUuid || state.accounts.length === 0) return null;
    return find(state.accounts, a => a.accountUuid === state.accountUuid);
  },

  accounts(state: Record<any, any>): GqlAccount[] {
    return state.accounts;
  },

  accessToken(state: Record<any, any>): string | null {
    return state.accessToken;
  }
};

const mutations = {
  setAccountUuid(state: Record<any, any>, accountUuid: string): void {
    state.accountUuid = accountUuid;
  },

  setAccounts(state: Record<any, any>, accounts: GqlAccount[]): void {
    const sortedAccounts = orderBy(accounts, "name");
    state.accounts = sortedAccounts;

    if (!state.accountUuid || !find(sortedAccounts, a => a.accountUuid === state.accountUuid)) {
      state.accountUuid = sortedAccounts[0].accountUuid;
    }
  },

  setAccessToken(state: Record<any, any>, token: string): void {
    state.accessToken = token;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
