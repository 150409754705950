import { omit } from "lodash";

export function omitHandler(handlers: Record<string, any>, omitKey: string): Record<string, any> {
  return omit(handlers, omitKey);
}

export function eventPos(e: MouseEvent | TouchEvent): [number, number] {
  if (e instanceof MouseEvent) {
    return [e.clientX, e.clientY];
  } else {
    const touch = e.changedTouches[0];
    return [touch.clientX, touch.clientY];
  }
}
