import { render, staticRenderFns } from "./AlertsSubscriptions.vue?vue&type=template&id=3c57ff11&"
import script from "./AlertsSubscriptions.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AlertsSubscriptions.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCardTitle,VIcon,VProgressCircular,VSpacer})
