export type KnownOperation = {
  operation: "create" | "update" | "delete" | "send_settings";
  object?: "ubx_alert_config" | "ubx_asset" | "ubx_threshold" | "ubx_floorplan" | "ubx_comment";
  name?: "createUserInvitation";
  if?: {
    field_changed?: string;
    field_set?: string;
    field_present?: string;
  };
  component: any;
};

export interface KnownOperationsResult {
  operationTester(op: KnownOperation, eventData: any): boolean;
  knownOperation(eventData: any): boolean;
  getPresentationComponent(eventData: any): any;
}

function operationTester(op: KnownOperation, eventData: any): boolean {
  const matchesOperation = op.operation === eventData.mutation?.operation;
  const matchesObject = !op.object || op.object === eventData.mutation?.object_type;
  const matchesName = !op.name || op.name === eventData.mutation?.name;
  const matchesIf =
    !op.if ||
    (op.if.field_set &&
      eventData.mutation?.changes.before[op.if.field_set] === null &&
      eventData.mutation?.changes.after[op.if.field_set] !== null) ||
    (op.if.field_changed &&
      eventData.mutation?.changes.before[op.if.field_changed] !== 0 &&
      eventData.mutation?.changes.before[op.if.field_changed] !==
        eventData.mutation?.changes.after[op.if.field_changed]) ||
    (op.if.field_present &&
      Object.keys(eventData.mutation?.changes.after).some(
        key => op.if?.field_present && key.startsWith(op.if?.field_present)
      ));

  return !!(matchesOperation && matchesObject && matchesName && matchesIf);
}

export function knownOperations(knownOperationList: KnownOperation[]): KnownOperationsResult {
  function knownOperation(eventData: any): boolean {
    return knownOperationList.some(op => operationTester(op, eventData));
  }

  function getPresentationComponent(eventData: any): any {
    const operation = knownOperationList.find(op => operationTester(op, eventData));
    return operation?.component;
  }

  return { operationTester, knownOperation, getPresentationComponent };
}
