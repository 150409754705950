import { ref, Ref } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import store from "@/store";
import { User } from "@/types";

export interface UseAccountAccessResult {
  users: Ref<User[]>;
  loadingError: Ref<boolean>;
  loadUsers: () => void;
  userRole: (user: User) => string;
  isAdmin: (user: User) => boolean;
  isOwner: (user: User) => boolean;
}

// Note: Users are not loaded by default, use `loadUsers` to trigger the request
export function useAccountAccess(): UseAccountAccessResult {
  const accountUuid = store.getters.accountUuid;
  const users: Ref<User[]> = ref([]);
  const loadingError = ref(false);
  let roleMap: { user: User; role: string }[] = [];

  const { onResult, onError, load, refetch } = useLazyQuery(
    gql`
      query AccountAccountAccessQuery($accountUuid: ID!) {
        UserGroupAccessInfo: accountAccess(accountUuid: $accountUuid) {
          usergroupUuid
          users {
            userUuid
            emailAddress
            activated
            contactInfo {
              accountUuid
              contactInfoUuid
              emailAddress
              emailEnable
              name
              ownerUuid
              phoneNumber
              smsEnable
              isUserContactInfo
              __typename
            }
          }
          singleUser
          role
          name
          ownerUuid
        }
      }
    `,
    { accountUuid: accountUuid }
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) {
      users.value = queryResult.data.UserGroupAccessInfo.filter((ug: any) => ug.singleUser).reduce(
        (acc: User[], ug: any) => {
          acc.push(ug.users[0]);
          return acc;
        },
        []
      );
      roleMap = queryResult.data.UserGroupAccessInfo.filter((ug: any) => ug.singleUser).reduce(
        (acc: { user: User; role: string }[], ug: any) => {
          acc.push({ user: ug.users[0], role: ug.role });
          return acc;
        },
        []
      );
    }
  });

  const loadUsers = (): Promise<any> | undefined => {
    return load() || refetch();
  };

  const userRole = (user: User): string => {
    return roleMap.find(userRole => userRole.user.userUuid === user?.userUuid)?.role || "";
  };

  const isAdmin = (user: User): boolean => {
    return ["admin", "owner"].includes(userRole(user));
  };

  const isOwner = (user: User): boolean => {
    return "owner" === userRole(user);
  };

  return { users, loadingError, loadUsers, userRole, isAdmin, isOwner };
}
