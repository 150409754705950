
import { Vue, Component, Watch } from "vue-property-decorator";
import gql from "graphql-tag";
import AssetComponent from "@/components/AssetComponent.vue";
import { decorateAsset, updateAssetProperties } from "@/config/asset";
import { DecoratedAsset, GqlBuilding } from "@/types";
import { deviceLock } from "@/gql/fragments/deviceLock";
import { useNavigationContext } from "@/composables/use-navigation-context";
import { stateChange } from "@/gql/fragments/state-change";

@Component({
  components: {
    AssetComponent
  },
  apollo: {
    asset: {
      query: gql`
        ${deviceLock}
        query AssetQuery($assetUuid: ID!) {
          asset(assetUuid: $assetUuid) {
            assetUuid
            knownAssetUuid
            name
            serialNumber
            building {
              buildingUuid
              name
              timeZone
              online
            }
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            installationDate
            childLinks {
              linkType
              childAsset {
                assetUuid
                knownAssetUuid
                name
              }
            }
            ... on Device {
              properties
              settings
              miscFields
              thresholds {
                values
              }
              ...DeviceLockFields
            }
          }
        }
      `,
      variables() {
        return {
          assetUuid: this.$route.params.assetUuid
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      result({ data }: Record<string, any>) {
        if (!data || !data.asset) return;

        if (this.initialLoading) {
          this.initialLoading = false;
          this.$store.commit("finishLoading");
        }

        this.asset = decorateAsset(data.asset);
      },
      error(): void {
        if (this.building === null) {
          this.$store.commit("finishLoadingWithError");
        }
      }
    },
    $subscribe: {
      assetDataChanges: {
        query: gql`
          ${stateChange}
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
              ...StateChangeFields
            }
          }
        `,
        variables() {
          return {
            assetUuids: [this.$route.params.assetUuid],
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return !this.asset?.knownAssetUuid || !this.$store.getters.accessToken;
        },
        result({ data }: Record<string, any>) {
          if (this.asset && data.assetDataChanges) {
            updateAssetProperties([this.asset], data.assetDataChanges);
          }
        }
      }
    }
  }
})
export default class AssetView extends Vue {
  asset: DecoratedAsset | null = null;
  initialLoading = true;

  created(): void {
    this.$store.commit("startLoading");
  }

  @Watch("asset", { immediate: true })
  setNavigationContext(): void {
    if (this.asset) {
      useNavigationContext({ building: this.asset.building, asset: this.asset });
    }
  }

  get building(): GqlBuilding | null {
    return this.asset?.building ?? null;
  }

  refreshAsset(): void {
    this.$apollo.queries.asset.refresh();
  }

  // eslint-disable-next-line
  beforeRouteLeave(to: any, from: any, next: () => void): void {
    // TODO This can be deleted when this component is converted to script setup.
    this.$store.commit("setContextualTimeZone", null);
    next();
  }
}
