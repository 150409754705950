import router from "@/router";

export function rootRoute(): Record<string, any> {
  return { name: "portfolio" };
}

export function fullUrl(route: Record<string, any>): string {
  const path = router.resolve(route).href;
  return new URL(path, window.location.origin).href;
}
