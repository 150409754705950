import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (contactUuid: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($contactUuid: ID!) {
          deleteContact(contactInfoUuid: $contactUuid)
        }
      `,
      variables: {
        contactUuid: contactUuid
      }
    })
    .then(response => {
      return response.data.deleteContact;
    });
}
