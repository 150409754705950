import { render, staticRenderFns } from "./InlineDateField.vue?vue&type=template&id=0852da6b&scoped=true&"
import script from "./InlineDateField.vue?vue&type=script&setup=true&lang=ts&"
export * from "./InlineDateField.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./InlineDateField.vue?vue&type=style&index=0&id=0852da6b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0852da6b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDatePicker,VHover,VIcon,VMenu})
