import { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import router from "@/router";

export const confirmedUserRedirectGuard = (to: Route, from: Route, next: NavigationGuardNext): void => {
  if (store.getters.user?.email_verified) {
    router.push({ name: "portfolio" });
  } else {
    return next();
  }
};
