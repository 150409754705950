import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlAccount, MaybeRef } from "@/types";

export interface UseAccounts {
  enabled?: MaybeRef<boolean>;
}

export interface UseAccountsResult {
  accounts: Ref<GqlAccount[] | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAccounts({ enabled = true }: UseAccounts = {}): UseAccountsResult {
  const accounts = ref<GqlAccount[] | undefined>();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AccountsQuery {
        accounts {
          accountUuid
          name
        }
      }
    `,
    {},
    () => ({
      enabled: unref(enabled),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      const gqlAccounts: GqlAccount[] = queryResult.data.accounts;
      accounts.value = gqlAccounts;
    }
  });

  return { accounts, loadingError, refetch, loading };
}
