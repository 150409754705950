import { ref, Ref } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import store from "@/store";
import { Contact } from "@/types";

export interface UseContactsResult {
  contacts: Ref<Contact[]>;
  loadingError: Ref<boolean>;
  loadContacts: () => void;
}

// Note: Contacts are not loaded by default, use `loadContacts` to trigger the request
export function useContacts(): UseContactsResult {
  const accountUuid = store.getters.accountUuid;
  const contacts: Ref<Contact[]> = ref([]);
  const loadingError = ref(false);

  const { onResult, onError, load, refetch } = useLazyQuery(
    gql`
      query AccountContactsQuery($accountUuid: ID!) {
        contacts: accountContacts(accountUuid: $accountUuid) {
          accountUuid
          contactInfoUuid
          emailAddress
          emailEnable
          name
          ownerUuid
          phoneNumber
          smsEnable
        }
      }
    `,
    { accountUuid: accountUuid }
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) contacts.value = queryResult.data.contacts;
  });

  const loadContacts = (): Promise<any> | undefined => {
    return load() || refetch();
  };

  return { contacts, loadingError, loadContacts };
}
