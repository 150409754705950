import { ref, unref, computed, Ref, ComputedRef } from "vue";
import { AlertConfig, AlertRecipient, Contact, MaybeRef, AlertConfigTree, BuildingNode } from "@/types";
import { orderBy } from "lodash";
import useAccountAlertConfigsLazyQuery from "@/gql/use-account-alert-configs-lazy-query";
import useBuildingAlertConfigsLazyQuery from "@/gql/use-building-alert-configs-lazy-query";
import useAssetAlertConfigsLazyQuery from "@/gql/use-asset-alert-configs-lazy-query";

export interface UseAlertConfigsResult {
  alertConfigs: Ref<AlertConfig[]>;
  sortedAlertConfigs: ComputedRef<AlertConfig[]>;
  alertConfigTree: ComputedRef<AlertConfigTree>;
  loading: Ref<boolean>;
  loadingError: Ref<boolean>;
  loadAlertConfigs: () => void;
  subscriptions: ComputedRef<Contact[]>;
  subscriptionsCount: ComputedRef<number>;
}

//
// Used to extract alertConfigs/subscriptions for an account or building
// Subscriptions are just the contacts extracted from the alertConfigs for convenience
// AlertConfigs are not loaded by default, use `loadAlertConfigs` to start loading
// it will return a promise for chaining which is resolved once the query is complete
//
export function useAlertConfigs(
  objectUuid: MaybeRef<string>,
  objectType: MaybeRef<"building" | "asset" | "account">
): UseAlertConfigsResult {
  const alertConfigs: Ref<AlertConfig[]> = ref([]);
  const loading = ref(false);
  const loadingError = ref(false);

  const alertQuery = () => {
    const oType = unref(objectType);
    if (oType === "building") return useBuildingAlertConfigsLazyQuery;
    else if (oType === "asset") return useAssetAlertConfigsLazyQuery;
    else return useAccountAlertConfigsLazyQuery;
  };

  const { onResult, onError, load, refetch } = alertQuery()(unref(objectUuid));

  const loadAlertConfigs = (): Promise<any> | undefined => {
    loading.value = true;

    return load() || refetch();
  };

  onError(() => {
    loading.value = false;
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (!queryResult.loading) {
      if (queryResult.data) {
        alertConfigs.value = queryResult.data.alertConfigs.filter((config: AlertConfig) => config.enabled);
      }
      loading.value = false;
    }
  });

  const subscriptions = computed(() => {
    if (!alertConfigs.value) return [] as Contact[];

    const subscribedContacts: Contact[] = [];
    alertConfigs.value
      .filter((config: AlertConfig) => config.enabled)
      .forEach((config: AlertConfig) => {
        config.recipients.forEach((recipient: AlertRecipient) => {
          subscribedContacts.push(recipient.contactInfo);
        });
      });
    return subscribedContacts;
  });

  const subscriptionsCount = computed(() => {
    return subscriptions.value.length;
  });

  const sortedAlertConfigs = computed(() => {
    return orderBy<AlertConfig>(
      alertConfigs.value.filter((config: AlertConfig) => config.enabled),
      [
        (ac: AlertConfig) => ac.building?.name,
        (ac: AlertConfig) => ac.assetUuid,
        (ac: AlertConfig) => ac.parameterName
      ],
      ["desc", "asc", "desc"]
    );
  });

  const alertConfigTree = computed(() => {
    const root: AlertConfigTree = { buildings: <BuildingNode[]>[] };

    sortedAlertConfigs.value.forEach((config: AlertConfig) => {
      if (config.building) {
        // create building if necessary
        let buildingNode = root.buildings.find(building => building.buildingName === config.building?.name);
        if (!buildingNode) {
          buildingNode = { buildingName: config.building.name, assets: [] };
          root.buildings.push(buildingNode);
        }
        // create asset if necessary
        let assetNode = buildingNode.assets.find(asset => asset.assetUuid === config.assetUuid);
        if (!assetNode) {
          assetNode = { assetUuid: config.assetUuid, properties: [] };
          buildingNode.assets.push(assetNode);
        }
        // lastly create the property if necessary
        let propertyNode = assetNode.properties.find(property => property.propertyName === config.parameterName);
        if (!propertyNode) {
          propertyNode = { propertyName: config.parameterName, alertConfigs: [] };
          assetNode.properties.push(propertyNode);
        }
        propertyNode.alertConfigs.push(config);
      }
    });

    return root;
  });

  return {
    alertConfigs,
    sortedAlertConfigs,
    alertConfigTree,
    loading,
    loadingError,
    loadAlertConfigs,
    subscriptions,
    subscriptionsCount
  };
}
