import { render, staticRenderFns } from "./GroupCard.vue?vue&type=template&id=a6775972&scoped=true&"
import script from "./GroupCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./GroupCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./GroupCard.vue?vue&type=style&index=0&id=a6775972&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6775972",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardSubtitle,VSpacer,VToolbar,VToolbarTitle})
