import { computed, ComputedRef, unref } from "vue";
import { Duration } from "luxon";
import { determinePropertyStatus } from "@/utils/models";
import { DecoratedAsset, DecoratedProperty, MetricIndicatorType, MaybeRef } from "@/types";

export interface UsePropertyIndicatorOptions {
  disableThresholds?: boolean;
  disableStalenessIndicator?: boolean;
}

export interface UsePropertyIndicatorResult {
  indicatorType: ComputedRef<MetricIndicatorType>;
}

export default function usePropertyIndicator(
  asset: MaybeRef<DecoratedAsset>,
  property: MaybeRef<DecoratedProperty>,
  relativeTimestamp?: MaybeRef<Duration | undefined>,
  options: UsePropertyIndicatorOptions = {}
): UsePropertyIndicatorResult {
  const { disableThresholds = false, disableStalenessIndicator = false } = options;

  const thresholdArray = computed(() => {
    return disableThresholds ? [] : unref(property).thresholdArray;
  });

  const indicatorType = computed(() => {
    const effectiveRelativeTimestamp = disableStalenessIndicator ? null : unref(relativeTimestamp);

    return determinePropertyStatus(
      unref(property).value,
      thresholdArray.value,
      effectiveRelativeTimestamp,
      unref(asset).config.staleDataDuration
    );
  });

  return {
    indicatorType
  };
}
