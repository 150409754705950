import { NavigationGuardNext, Route } from "vue-router";
import { getInstance } from "@/plugins/auth0";
import store from "@/store";
import router from "@/router";

export const authenticationGuard = (to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.meta?.authNotRequired) return next();

  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      const verified = store.getters.user.email_verified ?? false;
      if (!verified && !to.meta?.confirmationNotRequired) {
        router.push({ name: "unconfirmed" });
      } else {
        return next();
      }
    } else {
      authService.loginWithRedirect({ appState: { targetPath: to.fullPath } });
    }
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading: boolean) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
