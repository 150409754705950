import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { FloorFields } from "@/types";

interface CreateFloorConfig extends FloorFields {
  position: number;
}

export default function (buildingUuid: string, parameters: CreateFloorConfig): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($buildingUuid: ID!, $parameters: CreateFloorConfig!) {
          createFloor(buildingUuid: $buildingUuid, parameters: $parameters) {
            statusCode
            message
            floorUuid
          }
        }
      `,
      variables: {
        buildingUuid,
        parameters
      }
    })
    .then(response => {
      return response.data.createFloor;
    });
}
