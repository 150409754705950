import { render, staticRenderFns } from "./BuildingEnergyChart.vue?vue&type=template&id=322b85ab&"
import script from "./BuildingEnergyChart.vue?vue&type=script&setup=true&lang=ts&"
export * from "./BuildingEnergyChart.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports