import Vue from "vue";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";

import accessibility from "highcharts/modules/accessibility";
import csv from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import hcMore from "highcharts/highcharts-more";
import heatmap from "highcharts/modules/heatmap";
import offline from "highcharts/modules/offline-exporting";
import stockInit from "highcharts/modules/stock";

Highcharts.setOptions({
  lang: {
    thousandsSep: ""
  }
});

export function initHighcharts(): void {
  accessibility(Highcharts);
  csv(Highcharts);
  exporting(Highcharts);
  hcMore(Highcharts);
  heatmap(Highcharts);
  offline(Highcharts);
  stockInit(Highcharts);

  Vue.use(HighchartsVue);
}
