import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import en from "vuetify/src/locale/en";

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    themes: {
      light: {
        primary: "#0076bc",
        secondary: "#7eaf53",
        accent: "#d6743c"
      }
    }
  },
  lang: {
    locales: { en },
    current: "en"
  }
});
