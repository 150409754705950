import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (buildingUuid: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($buildingUuid: ID!) {
          deleteBuilding(buildingUuid: $buildingUuid)
        }
      `,
      variables: {
        buildingUuid: buildingUuid
      }
    })
    .then(response => {
      return response.data.deleteBuilding;
    });
}
