import gql from "graphql-tag";
import features from "@/config/features";

const deviceLockEnabled = gql`
  fragment DeviceLockFields on Device {
    deviceLock {
      expiration
    }
  }
`;

const deviceLockDisabled = gql`
  fragment DeviceLockFields on Device {
    __typename
  }
`;

export const deviceLock = features.stateManagement ? deviceLockEnabled : deviceLockDisabled;
