import { ref, Ref, unref } from "vue";
import { GenericEvent, MaybeRef, PageInfo } from "@/types";
import { orderBy } from "lodash";
import useAccountEventsLazyQuery from "@/gql/use-account-events-lazy-query";
import useBuildingEventsLazyQuery from "@/gql/use-building-events-lazy-query";
import useAssetEventsLazyQuery from "@/gql/use-asset-events-lazy-query";
import { events } from "@/composables/fixtures/events";

export type EventFilterType = "note" | "config_change" | "alert" | "mutation" | "all";

export interface UseEventsResult {
  events: Ref<GenericEvent[]>;
  loadingError: Ref<boolean>;
  loadEvents: () => void;
  loading: Ref<boolean>;
  pageInfo: Ref<PageInfo>;
}

// Set to true to generate fake events for testing
const GENERATE_FAKE_EVENTS = false;
const fakeEvents: GenericEvent[] = events;

function computeEventType(eventFilter: EventFilterType) {
  if (["alert", "mutation"].includes(eventFilter || "")) return eventFilter;
  else if (eventFilter === "config_change") return "mutation";
  else return undefined;
}

function computeFilters(eventFilter: EventFilterType) {
  const eventType = computeEventType(eventFilter);
  const noteFilter = eventFilter === "note";
  const mutationNames = eventFilter === "config_change" ? ["updateDeviceSettings"] : undefined;
  const excludeMutationNames =
    eventFilter === "mutation" ? ["updateDeviceSettings", "createComment", "refreshDeviceSettings"] : undefined;

  return { eventType, noteFilter, mutationNames, excludeMutationNames };
}

// Note: Events are lazyLoaded, use `loadEvents` to trigger the request
export function useEvents(
  limit: MaybeRef<number>,
  offset: MaybeRef<number>,
  eventFilter: MaybeRef<EventFilterType>,
  objectType: "account" | "building" | "asset",
  objectUuid: MaybeRef<string>
): UseEventsResult {
  const events: Ref<GenericEvent[]> = ref([]);
  const loadingError = ref(false);
  const pageInfo: Ref<PageInfo> = ref({ totalCount: 0, pageCount: 0, limit: 0, offset: 0 });

  const eventQuery = () => {
    if (objectType === "building") return useBuildingEventsLazyQuery;
    else if (objectType === "asset") return useAssetEventsLazyQuery;
    else return useAccountEventsLazyQuery;
  };

  const { eventType, noteFilter, mutationNames, excludeMutationNames } = computeFilters(unref(eventFilter));
  const { onResult, onError, load, refetch, loading } = eventQuery()(
    unref(objectUuid),
    eventType,
    noteFilter,
    mutationNames,
    excludeMutationNames,
    unref(limit),
    unref(offset)
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) {
      events.value = orderBy<GenericEvent>(
        queryResult.data.eventPage.data,
        [
          (ev: GenericEvent) => ev.stamp,
          (ev: GenericEvent) => ev.eventData?.alert?.assetUuid,
          (ev: GenericEvent) => ev.eventData?.alert?.parameter
        ],
        ["desc", "desc", "desc"]
      );
      if (GENERATE_FAKE_EVENTS) events.value = events.value.concat(fakeEvents);
      pageInfo.value = queryResult.data.eventPage.pageInfo;
    }
  });

  const loadEvents = (): Promise<any> | undefined => {
    const { eventType, noteFilter, mutationNames, excludeMutationNames } = computeFilters(unref(eventFilter));
    const args = {
      objectUuid: unref(objectUuid),
      eventType,
      noteFilter,
      mutationNames,
      excludeMutationNames,
      limit: unref(limit),
      offset: unref(offset)
    };
    return load(null, args) || refetch(args);
  };

  return { events, loadingError, loadEvents, loading, pageInfo };
}
