import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (uuids: string[]): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($uuids: [ID!]!) {
          deleteDocuments(uuids: $uuids) {
            message
            statusCode
          }
        }
      `,
      variables: {
        uuids: uuids
      }
    })
    .then(response => {
      return response.data.deleteDocuments;
    });
}
