import { ref, Ref, computed } from "vue";
import { Document, LocationType, DecoratedAsset } from "@/types";
import { useDocuments } from "@/composables/use-documents";

export interface UseAssetImagesResult {
  assetImageDocuments: Ref<Document[]>;
  loadingError: Ref<boolean>;
  loading: Ref<boolean>;
  loadImages: () => Promise<any> | undefined;
}

export function useAssetImages(asset: Ref<DecoratedAsset>): UseAssetImagesResult {
  const loadingError = ref(false);

  const assetID = computed(() => {
    return asset.value.assetUuid;
  });
  const { documents, loading, loadDocuments } = useDocuments(LocationType.Asset, assetID);

  const loadImages = () => {
    return loadDocuments();
  };

  const assetImageDocuments: Ref<Document[]> = computed(() => {
    return documents.value
      .filter((document: Document) => {
        return document.mimeType.startsWith("image");
      })
      .sort((doc1: Document, doc2: Document) => doc1.name.localeCompare(doc2.name));
  });

  return { assetImageDocuments, loadingError, loading, loadImages };
}
