import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useLazyQueryGenericResult } from "./use-lazy-query-generic-result";
import { AlertConfig } from "@/types";

export default function useAccountAlertConfigsLazyQuery(
  accountUuid: string
): useLazyQueryGenericResult<{ accountUuid: string }, { alertConfigs: AlertConfig[] }> {
  return useLazyQuery(
    gql`
      query AccountAlertConfigsQuery($accountUuid: ID!) {
        alertConfigs: accountAlertConfigs(accountUuid: $accountUuid) {
          alertConfigUuid
          alertName
          parameterName
          timeout
          enabled
          assetUuid
          buildingUuid
          building {
            name
          }
          recipients {
            alertRecipientUuid
            contactInfoUuid
            enable
            contactInfo {
              accountUuid
              contactInfoUuid
              emailAddress
              emailEnable
              name
              ownerUuid
              phoneNumber
              smsEnable
              isUserContactInfo
            }
          }
        }
      }
    `,
    { accountUuid: accountUuid },
    {
      fetchPolicy: "no-cache",
      // added due to loading stats issue with apollo
      // https://github.com/vuejs/apollo/issues/501
      notifyOnNetworkStatusChange: true
    }
  );
}
