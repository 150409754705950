import { render, staticRenderFns } from "./AssetUpdateMutationPresenter.vue?vue&type=template&id=2783c0b8&"
import script from "./AssetUpdateMutationPresenter.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AssetUpdateMutationPresenter.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
