import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";
import { ContactFields } from "@/types";

export default function (contact: ContactFields): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $contact: ContactCreate!) {
          createContact(accountUuid: $accountUuid, contact: $contact)
        }
      `,
      variables: {
        accountUuid: store.getters.accountUuid,
        contact: contact
      }
    })
    .then(response => {
      return response.data.contactCreate;
    });
}
