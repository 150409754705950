async function preloadImage(url: string) {
  const image = new Image();

  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
}

export default {
  async preloadImage({ commit }: Record<string, any>, url: string): Promise<void> {
    await preloadImage(url);
    commit("setImagePreloaded", url);
  }
};
