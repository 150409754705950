import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

// We delete users by setting their account access to null
export default function (userUuid: string, role: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $role: UserRole, $userUuid: ID!) {
          updateAccountAccess(accountUuid: $accountUuid, role: $role, userUuid: $userUuid)
        }
      `,
      variables: {
        accountUuid: store.getters.accountUuid,
        role: role.toUpperCase(),
        userUuid: userUuid
      }
    })
    .then(response => {
      return response.data.deleteUser;
    });
}
