import { extend, configure } from "vee-validate";
import { required, between, integer, excluded, min_value, email } from "vee-validate/dist/rules";
import "./custom-rules";
import { lookupRuleMessage } from "./utils";

extend("required", required);
extend("between", between);
extend("integer", integer);
extend("excluded", excluded);
extend("min_value", min_value);
extend("email", email);

configure({
  defaultMessage: lookupRuleMessage
});
