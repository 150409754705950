<script>
// workaround for right menus not working properly in vuetify 2
// https://github.com/vuetifyjs/vuetify/issues/13017#issuecomment-768320398
// This patch calculates the right position and fixes the width
// rather than  v-menu default which calculates the left position and the width

import VMenu from "vuetify/lib/components/VMenu/VMenu";

export default {
  name: "RightMenu",
  extends: VMenu,
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    styles() {
      return {
        maxHeight: this.calculatedMaxHeight,
        minWidth: this.calculatedMinWidth,
        maxWidth: this.calculatedMaxWidth,
        width: "275px", // this is necessary as recaculating the width is not reliable
        top: this.calculatedTop,
        right: document.body.clientWidth - this.dimensions.activator.right + "px",
        transformOrigin: this.origin,
        zIndex: this.zIndex || this.activeZIndex
      };
    }
  }
};
</script>
