export default {
  lang: {
    thousandsSep: ""
  },
  credits: {
    enabled: false
  },
  title: {
    text: undefined,
    align: "left",
    y: 20,
    style: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: "24px",
      lineHeight: "32px",
      color: "rgba(0, 0, 0, 0.87)"
    }
  },
  subtitle: {
    text: undefined,
    align: "left",
    style: {
      color: "#555",
      fontSize: "12px"
    }
  },
  navigation: {
    buttonOptions: {
      enabled: false,
      verticalAlign: "top",
      y: 2
    }
  },
  rangeSelector: {
    enabled: false,
    buttons: []
  }
};
