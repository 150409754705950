import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (floorUuid: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($floorUuid: ID!) {
          deleteFloor(floorUuid: $floorUuid) {
            statusCode
            message
          }
        }
      `,
      variables: {
        floorUuid
      }
    })
    .then(response => {
      return response.data.deleteFloor;
    });
}
