export function groupByToArray<K, V>(
  collection: V[],
  groupByFn: (x: V) => K,
  groupIdFn?: (x: K) => string | number
): [K, V[]][] {
  const groupKeys = new Map<string | number | K, K>();
  const groups = new Map<string | number | K, V[]>();

  for (const item of collection) {
    const key = groupByFn(item);
    const keyId = groupIdFn ? groupIdFn(key) : key;
    groupKeys.set(keyId, key);

    // Check if key exists, else intialize the value with blank array
    const value = groups.get(keyId) || [];
    value.push(item);
    groups.set(keyId, value);
  }

  return [...groups.entries()].map(([keyId, value]) => {
    const key: K = groupKeys.get(keyId) as K;
    return [key, value];
  });
}
