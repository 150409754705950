import { FormConfig } from "@/types";
import { readFormConfig } from "./form";

export const formConfig: FormConfig = readFormConfig({
  i18nNamespace: "floorplan",
  fields: {
    image: {
      dataType: "file",
      rules: { required: true }
    },
    sizeX: {
      dataType: "number",
      format: "integer",
      rules: {
        required: true,
        integer: true,
        min_value: 1
      }
    },
    sizeY: {
      dataType: "number",
      format: "integer",
      rules: {
        required: true,
        integer: true,
        min_value: 1
      }
    }
  }
});
