import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { pick } from "lodash";
import { Asset, GqlAsset } from "@/types";

export default function (asset: Partial<Asset>): Promise<GqlAsset> {
  const fields = pick(asset, ["buildingUuid", "name", "serialNumber"]);
  const properties = pick(asset, [
    "assetCategoryUuid",
    "assetTypeUuid",
    "manufacturerUuid",
    "assetModelUuid",
    "installationDate"
  ]);

  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($buildingUuid: ID!, $name: String!, $properties: CreateAssetProperties, $serialNumber: String) {
          createAsset(buildingUuid: $buildingUuid, name: $name, properties: $properties, serialNumber: $serialNumber) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            installationDate
          }
        }
      `,
      variables: {
        ...fields,
        properties
      }
    })
    .then(response => {
      return {
        ...response.data.createAsset,
        properties: {},
        thresholds: {}
      };
    });
}
