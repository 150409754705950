import { WritableComputedRef, computed, watch } from "vue";

type OpenCloseCallback = () => void;

interface UseDialogStateResults {
  isOpen: WritableComputedRef<boolean>;
  close(): void;
  onOpen(callback: OpenCloseCallback): void;
  onClose(callback: OpenCloseCallback): void;
}

export function useDialogState(
  props: { value: boolean },
  emit: (e: "input", value: boolean) => void
): UseDialogStateResults {
  let openCallback: OpenCloseCallback | undefined;
  let closeCallback: OpenCloseCallback | undefined;

  const isOpen = computed({
    get: () => props.value,
    set: newValue => emit("input", newValue)
  });

  function handleStateChange(newValue: boolean, oldValue: boolean | undefined = undefined): void {
    if (newValue) {
      if (openCallback) openCallback();
    } else {
      if (oldValue && closeCallback) closeCallback();
    }
  }

  watch(() => props.value, handleStateChange, { immediate: true });

  function close(): void {
    isOpen.value = false;
  }

  function onOpen(callback: OpenCloseCallback): void {
    openCallback = callback;
    handleStateChange(isOpen.value);
  }

  function onClose(callback: OpenCloseCallback): void {
    closeCallback = callback;
    handleStateChange(isOpen.value);
  }

  return { isOpen, close, onOpen, onClose };
}
