import gql from "graphql-tag";

export const commentFields = gql`
  fragment CommentFields on Comment {
    commentUuid
    comment
    stamp
    updated
    user {
      emailAddress
      contactInfo {
        emailAddress
        name
      }
    }
  }
`;
