import { render, staticRenderFns } from "./DeleteGroupDialog.vue?vue&type=template&id=1c3cf17f&"
import script from "./DeleteGroupDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DeleteGroupDialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCardText,VSpacer})
