import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { pick } from "lodash";
import { Asset, GqlAsset } from "@/types";

export default function (asset: Partial<Asset>): Promise<GqlAsset> {
  const fields = pick(asset, [
    "floorUuid",
    "name",
    "assetCategoryUuid",
    "assetTypeUuid",
    "manufacturerUuid",
    "assetModelUuid",
    "serialNumber",
    "installationDate",
    "floorX",
    "floorY"
  ]);

  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($assetUuid: ID!, $parameters: UpdateAssetParams!) {
          updateAsset(assetUuid: $assetUuid, parameters: $parameters) {
            assetUuid
            knownAssetUuid
            assetCategory {
              name
            }
            assetType {
              name
            }
            manufacturer {
              name
            }
            assetModel {
              name
            }
            smart
            online
            assetCategoryUuid
            assetTypeUuid
            manufacturerUuid
            assetModelUuid
            name
            serialNumber
            floorX
            floorY
            installationDate
          }
        }
      `,
      variables: {
        assetUuid: asset.assetUuid,
        parameters: fields
      }
    })
    .then(response => {
      return {
        ...response.data.updateAsset,
        properties: {},
        thresholds: {}
      };
    });
}
