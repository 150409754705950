import Vue from "vue";
import VueI18n, { LocaleMessages, TranslateResult, Values } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  en: {
    integer: {
      style: "decimal",
      maximumFractionDigits: 0
    },
    decimal1dd: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    },
    decimal2d: {
      style: "decimal",
      maximumFractionDigits: 2
    },
    decimal2dd: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  numberFormats
});

export default i18n;

export function tFallback(keys: string[], values?: Values): TranslateResult {
  const foundKey = keys.find(k => i18n.te(k));
  if (!foundKey) return keys[0] ?? "missing-key";
  return i18n.t(foundKey, values);
}

export function tFallbackInPaths(key: string, paths: string[], values?: Values): TranslateResult {
  const keys = paths.map(p => `${p}.${key}`);
  return tFallback(keys, values);
}
