import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { AlertConfig } from "@/types";
import { useLazyQueryGenericResult } from "./use-lazy-query-generic-result";

export default function useBuildingAccountConfigsLazyQuery(
  buildingUuid: string
): useLazyQueryGenericResult<{ buildingUuid: string }, { alertConfigs: AlertConfig[] }> {
  return useLazyQuery(
    gql`
      query BuildingAlertConfigsQuery($buildingUuid: ID!) {
        alertConfigs: buildingAlertConfigs(buildingUuid: $buildingUuid) {
          alertConfigUuid
          alertName
          parameterName
          timeout
          enabled
          assetUuid
          buildingUuid
          building {
            name
          }
          recipients {
            alertRecipientUuid
            contactInfoUuid
            enable
            contactInfo {
              accountUuid
              contactInfoUuid
              emailAddress
              emailEnable
              name
              ownerUuid
              phoneNumber
              smsEnable
              isUserContactInfo
            }
          }
        }
      }
    `,
    { buildingUuid: buildingUuid },
    {
      fetchPolicy: "no-cache",
      // added due to loading stats issue with apollo
      // https://github.com/vuejs/apollo/issues/501
      notifyOnNetworkStatusChange: true
    }
  );
}
