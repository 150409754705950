import { GenericEvent } from "@/types";
import { DateTime } from "luxon";

export const events: GenericEvent[] = [
  {
    eventUuid: "f9c8cf11-a0b9-4aee-bf10-0d7a73df89d5",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
    ownerUuid: "2f52899a-e89c-49cb-87e3-c4e7ba40c632",
    stamp: DateTime.fromISO("2024-08-22T21:03:25.101706+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
          settings: {
            child_lock__fan_mode: false,
            child_lock__reset: false,
            child_lock__system: false,
            child_lock__temp_control_mode: true,
            child_lock__temp_down: false,
            child_lock__temp_up: false,
            dst__enabled: true,
            dst__end_day: "Sunday",
            dst__end_hour: 2,
            dst__end_month: "November",
            dst__end_week: 1,
            dst__start_day: "Sunday",
            dst__start_hour: 2,
            dst__start_month: "March",
            dst__start_week: 2,
            fan_mode: "AUTO",
            "schedule_content__fan_mode[0]": "CIRCULATE",
            "schedule_content__fan_mode[1]": "CIRCULATE",
            "schedule_content__fan_mode[2]": "CIRCULATE",
            "schedule_content__fan_mode[3]": "AUTO",
            "schedule_content__target_temp[0]": 20.555555555555557,
            "schedule_content__target_temp[1]": 20,
            "schedule_content__target_temp[2]": 21.11111111111111,
            "schedule_content__target_temp[3]": 21.11111111111111,
            "schedule_content__tolerance_temp[0]": 0.5555555555555556,
            "schedule_content__tolerance_temp[1]": 0.5555555555555556,
            "schedule_content__tolerance_temp[2]": 0.5555555555555556,
            "schedule_content__tolerance_temp[3]": 2.2222222222222223,
            "schedule_time__days[0][0]": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
            "schedule_time__enabled[0][0]": true,
            "schedule_time__time[0][0]": "08:00",
            system_status: true,
            temperature_control_mode__mode: "COOL",
            "temperature_control_mode__point[2]": 27.77777777777778,
            temperature_tolerance__control: 1.1111111111111112,
            temperature_tolerance__point: 0.5555555555555556,
            time_zone: -8
          }
        },
        changes: {
          after: {
            _downlinked: [
              "schedule_time__enabled[0][0]",
              "schedule_time__days[0][0]",
              "schedule_time__time[0][0]",
              "temperature_control_mode__point[2]",
              "temperature_control_mode__mode",
              "child_lock__system",
              "child_lock__temp_up",
              "child_lock__temp_down",
              "child_lock__fan_mode",
              "child_lock__temp_control_mode",
              "child_lock__reset",
              "system_status",
              "temperature_tolerance__point",
              "temperature_tolerance__control",
              "dst__enabled",
              "dst__bias",
              "dst__start_month",
              "dst__start_week",
              "dst__start_day",
              "dst__start_hour",
              "dst__end_month",
              "dst__end_week",
              "dst__end_day",
              "dst__end_hour",
              "fan_mode",
              "time_zone",
              "schedule_content__fan_mode[1]",
              "schedule_content__target_temp[1]",
              "schedule_content__tolerance_temp[1]",
              "schedule_content__fan_mode[3]",
              "schedule_content__target_temp[3]",
              "schedule_content__tolerance_temp[3]",
              "schedule_content__fan_mode[2]",
              "schedule_content__target_temp[2]",
              "schedule_content__tolerance_temp[2]",
              "schedule_content__fan_mode[0]",
              "schedule_content__target_temp[0]",
              "schedule_content__tolerance_temp[0]"
            ],
            child_lock__fan_mode: false,
            child_lock__reset: false,
            child_lock__system: false,
            child_lock__temp_control_mode: true,
            child_lock__temp_down: false,
            child_lock__temp_up: false,
            dst__bias: 60,
            dst__enabled: true,
            dst__end_day: "Sunday",
            dst__end_hour: 2,
            dst__end_month: "November",
            dst__end_week: 1,
            dst__start_day: "Sunday",
            dst__start_hour: 2,
            dst__start_month: "March",
            dst__start_week: 2,
            fan_mode: "AUTO",
            "schedule_content__fan_mode[0]": "CIRCULATE",
            "schedule_content__fan_mode[1]": "CIRCULATE",
            "schedule_content__fan_mode[2]": "CIRCULATE",
            "schedule_content__fan_mode[3]": "AUTO",
            "schedule_content__target_temp[0]": 20.555555555555557,
            "schedule_content__target_temp[1]": 20,
            "schedule_content__target_temp[2]": 21.11111111111111,
            "schedule_content__target_temp[3]": 21.11111111111111,
            "schedule_content__tolerance_temp[0]": 0.5555555555555556,
            "schedule_content__tolerance_temp[1]": 0.5555555555555556,
            "schedule_content__tolerance_temp[2]": 0.5555555555555556,
            "schedule_content__tolerance_temp[3]": 2.2222222222222223,
            "schedule_time__days[0][0]": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
            "schedule_time__enabled[0][0]": true,
            "schedule_time__time[0][0]": "08:00",
            system_status: true,
            temperature_control_mode__mode: "COOL",
            "temperature_control_mode__point[2]": 27.77777777777778,
            temperature_tolerance__control: 1.1111111111111112,
            temperature_tolerance__point: 0.5555555555555556,
            time_zone: -8
          },
          before: {
            _downlinked: [],
            child_lock__fan_mode: false,
            child_lock__reset: false,
            child_lock__system: false,
            child_lock__temp_control_mode: true,
            child_lock__temp_down: false,
            child_lock__temp_up: false,
            dst__bias: 60,
            dst__enabled: true,
            dst__end_day: "Sunday",
            dst__end_hour: 2,
            dst__end_month: "November",
            dst__end_week: 1,
            dst__start_day: "Sunday",
            dst__start_hour: 2,
            dst__start_month: "March",
            dst__start_week: 2,
            fan_mode: "AUTO",
            "schedule_content__fan_mode[0]": "CIRCULATE",
            "schedule_content__fan_mode[1]": "CIRCULATE",
            "schedule_content__fan_mode[2]": "CIRCULATE",
            "schedule_content__fan_mode[3]": "AUTO",
            "schedule_content__target_temp[0]": 20.555555555555557,
            "schedule_content__target_temp[1]": 20,
            "schedule_content__target_temp[2]": 21.11111111111111,
            "schedule_content__target_temp[3]": 21.11111111111111,
            "schedule_content__tolerance_temp[0]": 0.5555555555555556,
            "schedule_content__tolerance_temp[1]": 0.5555555555555556,
            "schedule_content__tolerance_temp[2]": 0.5555555555555556,
            "schedule_content__tolerance_temp[3]": 2.2222222222222223,
            "schedule_time__days[0][0]": [],
            "schedule_time__enabled[0][0]": false,
            "schedule_time__time[0][0]": "0:00",
            system_status: true,
            temperature_control_mode__mode: "COOL",
            "temperature_control_mode__point[2]": 27.700000000000003,
            temperature_tolerance__control: 1.1111111111111112,
            temperature_tolerance__point: 0.5555555555555556,
            time_zone: -8
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "324247af-40c3-49fa-8784-701a8f31e95a",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
      knownAssetUuid: "3c67bdfe-1a0a-44e8-8c5c-82885deb682a",
      name: "RTU Tstat"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL73E3KNE7B&Signature=X2s06u8JB1%2F%2BTHa%2BI7bLl21OgpY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIHemQiTJQQtIDqmifMIHIySVnmC6YIZ2HoVbNfhnqc5SAiEA9gYt%2B72zGtLP1dYYvRe%2Bk2DUJhnPvf%2FZiSgx%2BC0PmXwquwUIlf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MDgzMjg3NDU3MjciDDrbwPBMQB2kZoOA3CqPBfhHKOTkipBXPSBa2X8L9gTtgIP7qXo9s6oYg9dXXuYeyv%2Bb67PIL%2Ff5JC%2FRtYW0TzMkZN8Nsf2%2BQYBJNtqQywVRrOs5elj5UJxyep9B15ubYdk3aMFWG25w1cVvC1%2BgxXNaB8wlw5RI%2BG%2F9PA%2Bxo6HgclmnxOd9mcu8akLJs1%2FexNhzCvWrCGzmpWwgOdzv4hSWNrBrkoBqrfSiKe99vZndX%2BpWP%2Bk86U1DAQEQ%2BTjI2a2lcp1wrbW5GbtkhKbN9H3LIkB3BSpqhobO1eyvX%2Fbz2p%2BdDODv3Ok9PkRoO9wzn71ryOaPv4u3U%2BEegwF9o4j0J6gaWdDXcPONrdBeubIkZYocoXaYyNl2GORtO5ukZO1ojBl3YYX8O3dBfgxPNVF5lw9tPui3PR3TzH6Z5%2BvxW%2B8YI%2BrtcMG9W6J6EKEHimV%2FOnhEhBsQg0VG5LRR%2Fapi4C%2FVrcT1r64efTo%2BxM%2B4QGtdjUbF7fG2GBGpNRf3m2pdqs1fFqdFkNL5oxmal8%2F2aY4v%2BvBabwB3ZqpaoetD%2Fs4OVTqfCZVcTKjMOvWdZzWVtYwSn8VNQJSD1th42IJoDe2cbTUqptMA38SN%2FWR%2FsVrRjYAIiISNTZTrFPf1wC23xzcwOCRpgnSF5pssEaYsOeSrtZZeWp8a58FOi1eUd79dPYcWYuaBHkVcIXOi1Fx6iMhjUuIHKdY1cMswGW%2FcUx3rs8bs1sxGaKqju2EQDTjVZ3vZzzTGib%2FK%2BsWvVT7VNOdT26cby1gBVddNeWP9jdD9e6BMB9YshFPepz%2BF753Wx8j9QxPNQ59Qedpmg%2BxyRfEtcdspCxxhGfTQIZninqI5awFCliwHPmHe7pMmiFK1L8vfH2LPJJwbFdgwh7OetgY6sQHVTFpVT5ok15uPM3bY8MaIJY%2BR81x%2B03q56Tom%2Buw%2FuVukVIdlzjXfpBBFAup6e9aFkbPfUwOnnCrSRbXH7CdppsIB13330fJLLRXumRumZx4Zrq4zWtf%2BkdTP0MSoKNgKq2D2cscDYLmuQN596DkzusPtayGReAqmC8Y%2BoeuSwIDa7Xqb3jJbaWLFAXTOJRAPspYuAK6gzGUo0ke%2B3IObx26jGiCof5OffKUoD3ch2%2B8%3D&Expires=1724362079"
      },
      position: 1
    },
    user: {
      emailAddress: "barry.paul+staging@gmail.com",
      contactInfo: {
        emailAddress: "bpaul@planet-hood.com",
        name: "Barry Paul"
      }
    }
  },
  {
    eventUuid: "95a70b2f-cdb8-431d-8028-5f1f7b9a153f",
    accountUuid: "511d3d55-b359-408a-be80-62d9caa7dbf6",
    buildingUuid: undefined,
    floorUuid: undefined,
    assetUuid: undefined,
    ownerUuid: "57540bde-a661-4189-b77a-5bf9393a5e44",
    stamp: DateTime.fromISO("2024-08-05T19:23:44.139455+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          account_uuid: "511d3d55-b359-408a-be80-62d9caa7dbf6",
          email_address: "barry.paul+emailtest@gmail.com",
          name: "null",
          role: "EDITOR"
        },
        changes: {
          after: {
            email_address: "barry.paul+emailtest@gmail.com",
            role: "EDITOR"
          },
          before: "null"
        },
        name: "createUserInvitation",
        object_type: "null",
        object_uuid: "null",
        operation: "create"
      }
    },
    asset: undefined,
    building: undefined,
    floor: undefined,
    user: {
      emailAddress: "barry.paul+admin2@gmail.com",
      contactInfo: {
        emailAddress: "barry.paul+admin2@gmail.com",
        name: "2nd Admin"
      }
    }
  },
  {
    eventUuid: "29c226ed-8576-417b-93c7-f833a7909d6e",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-08-09T22:05:38.015710+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
          settings: {
            boost_flow: 100,
            boost_timer: 5,
            economizer_enabled: false,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "25:00",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__end[0][3]": "25:00",
            "hrv_schedule__end[0][4]": "25:00",
            "hrv_schedule__end[0][5]": "25:00",
            "hrv_schedule__end[0][6]": "25:00",
            "hrv_schedule__end[0][7]": "25:00",
            "hrv_schedule__end[0][8]": "25:00",
            "hrv_schedule__end[1][1]": "25:00",
            "hrv_schedule__end[1][2]": "25:00",
            "hrv_schedule__end[1][3]": "25:00",
            "hrv_schedule__end[1][4]": "25:00",
            "hrv_schedule__end[1][5]": "25:00",
            "hrv_schedule__end[1][6]": "25:00",
            "hrv_schedule__end[1][7]": "25:00",
            "hrv_schedule__end[1][8]": "25:00",
            "hrv_schedule__end[2][1]": "25:00",
            "hrv_schedule__end[2][2]": "25:00",
            "hrv_schedule__end[2][3]": "25:00",
            "hrv_schedule__end[2][4]": "25:00",
            "hrv_schedule__end[2][5]": "25:00",
            "hrv_schedule__end[2][6]": "25:00",
            "hrv_schedule__end[2][7]": "25:00",
            "hrv_schedule__end[2][8]": "25:00",
            "hrv_schedule__end[3][1]": "25:00",
            "hrv_schedule__end[3][2]": "25:00",
            "hrv_schedule__end[3][3]": "25:00",
            "hrv_schedule__end[3][4]": "25:00",
            "hrv_schedule__end[3][5]": "25:00",
            "hrv_schedule__end[3][6]": "25:00",
            "hrv_schedule__end[3][7]": "25:00",
            "hrv_schedule__end[3][8]": "25:00",
            "hrv_schedule__end[4][1]": "25:00",
            "hrv_schedule__end[4][2]": "25:00",
            "hrv_schedule__end[4][3]": "25:00",
            "hrv_schedule__end[4][4]": "25:00",
            "hrv_schedule__end[4][5]": "25:00",
            "hrv_schedule__end[4][6]": "25:00",
            "hrv_schedule__end[4][7]": "25:00",
            "hrv_schedule__end[4][8]": "25:00",
            "hrv_schedule__end[5][1]": "25:00",
            "hrv_schedule__end[5][2]": "25:00",
            "hrv_schedule__end[5][3]": "25:00",
            "hrv_schedule__end[5][4]": "25:00",
            "hrv_schedule__end[5][5]": "25:00",
            "hrv_schedule__end[5][6]": "25:00",
            "hrv_schedule__end[5][7]": "25:00",
            "hrv_schedule__end[5][8]": "25:00",
            "hrv_schedule__end[6][1]": "25:00",
            "hrv_schedule__end[6][2]": "25:00",
            "hrv_schedule__end[6][3]": "25:00",
            "hrv_schedule__end[6][4]": "25:00",
            "hrv_schedule__end[6][5]": "25:00",
            "hrv_schedule__end[6][6]": "25:00",
            "hrv_schedule__end[6][7]": "25:00",
            "hrv_schedule__end[6][8]": "25:00",
            "hrv_schedule__start[0][1]": "25:00",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__start[0][3]": "25:00",
            "hrv_schedule__start[0][4]": "25:00",
            "hrv_schedule__start[0][5]": "25:00",
            "hrv_schedule__start[0][6]": "25:00",
            "hrv_schedule__start[0][7]": "25:00",
            "hrv_schedule__start[0][8]": "25:00",
            "hrv_schedule__start[1][1]": "25:00",
            "hrv_schedule__start[1][2]": "25:00",
            "hrv_schedule__start[1][3]": "25:00",
            "hrv_schedule__start[1][4]": "25:00",
            "hrv_schedule__start[1][5]": "25:00",
            "hrv_schedule__start[1][6]": "25:00",
            "hrv_schedule__start[1][7]": "25:00",
            "hrv_schedule__start[1][8]": "25:00",
            "hrv_schedule__start[2][1]": "25:00",
            "hrv_schedule__start[2][2]": "25:00",
            "hrv_schedule__start[2][3]": "25:00",
            "hrv_schedule__start[2][4]": "25:00",
            "hrv_schedule__start[2][5]": "25:00",
            "hrv_schedule__start[2][6]": "25:00",
            "hrv_schedule__start[2][7]": "25:00",
            "hrv_schedule__start[2][8]": "25:00",
            "hrv_schedule__start[3][1]": "25:00",
            "hrv_schedule__start[3][2]": "25:00",
            "hrv_schedule__start[3][3]": "25:00",
            "hrv_schedule__start[3][4]": "25:00",
            "hrv_schedule__start[3][5]": "25:00",
            "hrv_schedule__start[3][6]": "25:00",
            "hrv_schedule__start[3][7]": "25:00",
            "hrv_schedule__start[3][8]": "25:00",
            "hrv_schedule__start[4][1]": "25:00",
            "hrv_schedule__start[4][2]": "25:00",
            "hrv_schedule__start[4][3]": "25:00",
            "hrv_schedule__start[4][4]": "25:00",
            "hrv_schedule__start[4][5]": "25:00",
            "hrv_schedule__start[4][6]": "25:00",
            "hrv_schedule__start[4][7]": "25:00",
            "hrv_schedule__start[4][8]": "25:00",
            "hrv_schedule__start[5][1]": "25:00",
            "hrv_schedule__start[5][2]": "25:00",
            "hrv_schedule__start[5][3]": "25:00",
            "hrv_schedule__start[5][4]": "25:00",
            "hrv_schedule__start[5][5]": "25:00",
            "hrv_schedule__start[5][6]": "25:00",
            "hrv_schedule__start[5][7]": "25:00",
            "hrv_schedule__start[5][8]": "25:00",
            "hrv_schedule__start[6][1]": "25:00",
            "hrv_schedule__start[6][2]": "25:00",
            "hrv_schedule__start[6][3]": "25:00",
            "hrv_schedule__start[6][4]": "25:00",
            "hrv_schedule__start[6][5]": "25:00",
            "hrv_schedule__start[6][6]": "25:00",
            "hrv_schedule__start[6][7]": "25:00",
            "hrv_schedule__start[6][8]": "25:00",
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 130,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: "null"
          }
        },
        changes: {
          after: {
            _downlinked: ["hrv_schedule__start[4][1]", "hrv_schedule__end[4][1]"],
            "hrv_schedule__end[4][1]": 6400,
            "hrv_schedule__start[4][1]": 6400,
            wireless_dcv_enable: null
          },
          before: {
            _downlinked: [],
            "hrv_schedule__end[4][1]": "14:40",
            "hrv_schedule__start[4][1]": "14:30",
            wireless_dcv_enable: null
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
      knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
      name: "HRV CM 499e"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7U4EWPTZM&Signature=t0KOsj92lITQusSZZeFn%2FrSXeHI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQDsXw0vMDGti6zJHTbt6Zqq4KVHp50cDH7YXZQUIqTEAQIgFKMm9vNSDcX4v6ahBzfcdFlxJl4Q5zHeDNollSArf08quwUIrP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MDgzMjg3NDU3MjciDCh4WPE48JNArUkKaiqPBUoyxTd3AuFCBR0rLu4%2FaaZnk0GMNHveFG4Bo%2BRyd67H1HDGDtTYFEchlBdsVW2kS58GSQy9QGrKQeF9HiOunuxkSDEz%2FV4tJpg850aQKhjUpiasxAG8rV9pN9DOYHszbYizVuebhY1hSu2yt02CJ2sC4xk3jmHdDK0HgZswl77RCORzS6tOQpSUOcw4rlznxEihet4JhfBstxLR3o4fId3KGcs6ad7G4WY2BJdQZ%2BVbP18wKn4n28SsQyvL8uUeVshTKNBO3070GB2J7uLHhZ%2FvBYc1Z%2FDO%2FP7p1FMU5ajKujFKwHcu2O2vjkeoPm%2FRYhdyB5Ygmge%2F%2BaIg9Op2kIIN%2FlU53RSrnlKKhXz4XG1Kis6fFCe5%2BVlVvsECYWafTw5lWUlmL3oZHrl9IN4mzMsqqrcGenOaZa5jS4sUpB11nd%2BQa8qJ%2FRc6e5%2BgAxKOi869QQkRjtRouzhueDIMF5EO3G8ELNy2YgUMeLVbMuILsJBB65%2BKoeafh1vs%2BUZexVJWhIUzMNSgr2chqIHjZ2mC%2FuFhuEinMLV7gLNVNWigB95PNgvf%2BebaUjF50CK2ll6oIEau3egYgTndD8PqcOuo4xN40bvTGFOA1LN1E2yZB9nNSuEYt0s5Q5tHSl4n%2BmUQJe6FuagCmtCp2JnqxDbxnHgbHCxtPJbOBZXEqE8tzt632rp%2Bky%2Foq1AIsZ6WivZRsBMsmYFZAFBDplkbsEwPtxPqYl4vLqhAH7Nkef%2F0QbzmAkmv3azFi2baz5jmXf%2B2wK%2FRqdJIZEq%2F7D%2BIT0E%2FozMv2cdYTZ8et4cpOm4DgZxnPAcAMxpILOXD%2BQJfEfyxqmVeoljcnY8ost3KRas3OVsJNaWjlrCQJBHlUcow39rutQY6sQH1lJKOglFHxmQksBpIw526oGQvCdcMiTCBcq%2FHuMyrQLueH915dtWiXFzl4DPyb5RptcqTyTNb4h1ViR4BJ2%2FkBUfOAyi95cV4lGkxrrT0yP8NCRYLzZROSInOtxzd%2BmD%2BcZroamAzv8C%2B88zk19Q6uKT6mfHk5wN9w3iPY4FeWTn9aFaB8VSUFK9uCRtXexPRDRFG3GF0uyGLox5sPjjBUUODhT%2BabvIKJZI5X%2B6iRbo%3D&Expires=1723586672"
      },
      position: 2
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "7a7b55b1-b551-45cd-b37b-fd1a83b2e06a",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-08-10T00:19:56.964324+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
          settings: {
            boost_flow: 100,
            boost_timer: 5,
            economizer_enabled: false,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "25:00",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__end[0][3]": "25:00",
            "hrv_schedule__end[0][4]": "25:00",
            "hrv_schedule__end[0][5]": "25:00",
            "hrv_schedule__end[0][6]": "25:00",
            "hrv_schedule__end[0][7]": "25:00",
            "hrv_schedule__end[0][8]": "25:00",
            "hrv_schedule__end[1][1]": "25:00",
            "hrv_schedule__end[1][2]": "25:00",
            "hrv_schedule__end[1][3]": "25:00",
            "hrv_schedule__end[1][4]": "25:00",
            "hrv_schedule__end[1][5]": "25:00",
            "hrv_schedule__end[1][6]": "25:00",
            "hrv_schedule__end[1][7]": "25:00",
            "hrv_schedule__end[1][8]": "25:00",
            "hrv_schedule__end[2][1]": "25:00",
            "hrv_schedule__end[2][2]": "25:00",
            "hrv_schedule__end[2][3]": "25:00",
            "hrv_schedule__end[2][4]": "25:00",
            "hrv_schedule__end[2][5]": "25:00",
            "hrv_schedule__end[2][6]": "25:00",
            "hrv_schedule__end[2][7]": "25:00",
            "hrv_schedule__end[2][8]": "25:00",
            "hrv_schedule__end[3][1]": "25:00",
            "hrv_schedule__end[3][2]": "25:00",
            "hrv_schedule__end[3][3]": "25:00",
            "hrv_schedule__end[3][4]": "25:00",
            "hrv_schedule__end[3][5]": "25:00",
            "hrv_schedule__end[3][6]": "25:00",
            "hrv_schedule__end[3][7]": "25:00",
            "hrv_schedule__end[3][8]": "25:00",
            "hrv_schedule__end[4][1]": "17:20",
            "hrv_schedule__end[4][2]": "25:00",
            "hrv_schedule__end[4][3]": "25:00",
            "hrv_schedule__end[4][4]": "25:00",
            "hrv_schedule__end[4][5]": "25:00",
            "hrv_schedule__end[4][6]": "25:00",
            "hrv_schedule__end[4][7]": "25:00",
            "hrv_schedule__end[4][8]": "25:00",
            "hrv_schedule__end[5][1]": "25:00",
            "hrv_schedule__end[5][2]": "25:00",
            "hrv_schedule__end[5][3]": "25:00",
            "hrv_schedule__end[5][4]": "25:00",
            "hrv_schedule__end[5][5]": "25:00",
            "hrv_schedule__end[5][6]": "25:00",
            "hrv_schedule__end[5][7]": "25:00",
            "hrv_schedule__end[5][8]": "25:00",
            "hrv_schedule__end[6][1]": "25:00",
            "hrv_schedule__end[6][2]": "25:00",
            "hrv_schedule__end[6][3]": "25:00",
            "hrv_schedule__end[6][4]": "25:00",
            "hrv_schedule__end[6][5]": "25:00",
            "hrv_schedule__end[6][6]": "25:00",
            "hrv_schedule__end[6][7]": "25:00",
            "hrv_schedule__end[6][8]": "25:00",
            "hrv_schedule__flow[4][1]": 200,
            "hrv_schedule__start[0][1]": "25:00",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__start[0][3]": "25:00",
            "hrv_schedule__start[0][4]": "25:00",
            "hrv_schedule__start[0][5]": "25:00",
            "hrv_schedule__start[0][6]": "25:00",
            "hrv_schedule__start[0][7]": "25:00",
            "hrv_schedule__start[0][8]": "25:00",
            "hrv_schedule__start[1][1]": "25:00",
            "hrv_schedule__start[1][2]": "25:00",
            "hrv_schedule__start[1][3]": "25:00",
            "hrv_schedule__start[1][4]": "25:00",
            "hrv_schedule__start[1][5]": "25:00",
            "hrv_schedule__start[1][6]": "25:00",
            "hrv_schedule__start[1][7]": "25:00",
            "hrv_schedule__start[1][8]": "25:00",
            "hrv_schedule__start[2][1]": "25:00",
            "hrv_schedule__start[2][2]": "25:00",
            "hrv_schedule__start[2][3]": "25:00",
            "hrv_schedule__start[2][4]": "25:00",
            "hrv_schedule__start[2][5]": "25:00",
            "hrv_schedule__start[2][6]": "25:00",
            "hrv_schedule__start[2][7]": "25:00",
            "hrv_schedule__start[2][8]": "25:00",
            "hrv_schedule__start[3][1]": "25:00",
            "hrv_schedule__start[3][2]": "25:00",
            "hrv_schedule__start[3][3]": "25:00",
            "hrv_schedule__start[3][4]": "25:00",
            "hrv_schedule__start[3][5]": "25:00",
            "hrv_schedule__start[3][6]": "25:00",
            "hrv_schedule__start[3][7]": "25:00",
            "hrv_schedule__start[3][8]": "25:00",
            "hrv_schedule__start[4][1]": "17:15",
            "hrv_schedule__start[4][2]": "25:00",
            "hrv_schedule__start[4][3]": "25:00",
            "hrv_schedule__start[4][4]": "25:00",
            "hrv_schedule__start[4][5]": "25:00",
            "hrv_schedule__start[4][6]": "25:00",
            "hrv_schedule__start[4][7]": "25:00",
            "hrv_schedule__start[4][8]": "25:00",
            "hrv_schedule__start[5][1]": "25:00",
            "hrv_schedule__start[5][2]": "25:00",
            "hrv_schedule__start[5][3]": "25:00",
            "hrv_schedule__start[5][4]": "25:00",
            "hrv_schedule__start[5][5]": "25:00",
            "hrv_schedule__start[5][6]": "25:00",
            "hrv_schedule__start[5][7]": "25:00",
            "hrv_schedule__start[5][8]": "25:00",
            "hrv_schedule__start[6][1]": "25:00",
            "hrv_schedule__start[6][2]": "25:00",
            "hrv_schedule__start[6][3]": "25:00",
            "hrv_schedule__start[6][4]": "25:00",
            "hrv_schedule__start[6][5]": "25:00",
            "hrv_schedule__start[6][6]": "25:00",
            "hrv_schedule__start[6][7]": "25:00",
            "hrv_schedule__start[6][8]": "25:00",
            "hrv_schedule__temp[4][1]": 21.11111111111111,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 130,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: "null"
          }
        },
        changes: {
          after: {
            _downlinked: ["iaq_sensor", "set_operating_mode"],
            iaq_sensor: 0,
            set_operating_mode: 2,
            wireless_dcv_enable: null
          },
          before: {
            _downlinked: [],
            iaq_sensor: "CO2_2000",
            set_operating_mode: "DCV",
            wireless_dcv_enable: null
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
      knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
      name: "HRV CM 499e"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7U4EWPTZM&Signature=t0KOsj92lITQusSZZeFn%2FrSXeHI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQDsXw0vMDGti6zJHTbt6Zqq4KVHp50cDH7YXZQUIqTEAQIgFKMm9vNSDcX4v6ahBzfcdFlxJl4Q5zHeDNollSArf08quwUIrP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MDgzMjg3NDU3MjciDCh4WPE48JNArUkKaiqPBUoyxTd3AuFCBR0rLu4%2FaaZnk0GMNHveFG4Bo%2BRyd67H1HDGDtTYFEchlBdsVW2kS58GSQy9QGrKQeF9HiOunuxkSDEz%2FV4tJpg850aQKhjUpiasxAG8rV9pN9DOYHszbYizVuebhY1hSu2yt02CJ2sC4xk3jmHdDK0HgZswl77RCORzS6tOQpSUOcw4rlznxEihet4JhfBstxLR3o4fId3KGcs6ad7G4WY2BJdQZ%2BVbP18wKn4n28SsQyvL8uUeVshTKNBO3070GB2J7uLHhZ%2FvBYc1Z%2FDO%2FP7p1FMU5ajKujFKwHcu2O2vjkeoPm%2FRYhdyB5Ygmge%2F%2BaIg9Op2kIIN%2FlU53RSrnlKKhXz4XG1Kis6fFCe5%2BVlVvsECYWafTw5lWUlmL3oZHrl9IN4mzMsqqrcGenOaZa5jS4sUpB11nd%2BQa8qJ%2FRc6e5%2BgAxKOi869QQkRjtRouzhueDIMF5EO3G8ELNy2YgUMeLVbMuILsJBB65%2BKoeafh1vs%2BUZexVJWhIUzMNSgr2chqIHjZ2mC%2FuFhuEinMLV7gLNVNWigB95PNgvf%2BebaUjF50CK2ll6oIEau3egYgTndD8PqcOuo4xN40bvTGFOA1LN1E2yZB9nNSuEYt0s5Q5tHSl4n%2BmUQJe6FuagCmtCp2JnqxDbxnHgbHCxtPJbOBZXEqE8tzt632rp%2Bky%2Foq1AIsZ6WivZRsBMsmYFZAFBDplkbsEwPtxPqYl4vLqhAH7Nkef%2F0QbzmAkmv3azFi2baz5jmXf%2B2wK%2FRqdJIZEq%2F7D%2BIT0E%2FozMv2cdYTZ8et4cpOm4DgZxnPAcAMxpILOXD%2BQJfEfyxqmVeoljcnY8ost3KRas3OVsJNaWjlrCQJBHlUcow39rutQY6sQH1lJKOglFHxmQksBpIw526oGQvCdcMiTCBcq%2FHuMyrQLueH915dtWiXFzl4DPyb5RptcqTyTNb4h1ViR4BJ2%2FkBUfOAyi95cV4lGkxrrT0yP8NCRYLzZROSInOtxzd%2BmD%2BcZroamAzv8C%2B88zk19Q6uKT6mfHk5wN9w3iPY4FeWTn9aFaB8VSUFK9uCRtXexPRDRFG3GF0uyGLox5sPjjBUUODhT%2BabvIKJZI5X%2B6iRbo%3D&Expires=1723586672"
      },
      position: 2
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "4a559b75-82c4-448b-bf16-a30bb5742e72",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-08-10T00:19:49.093285+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
          settings: {
            boost_flow: 100,
            boost_timer: 5,
            economizer_enabled: false,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "25:00",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__end[0][3]": "25:00",
            "hrv_schedule__end[0][4]": "25:00",
            "hrv_schedule__end[0][5]": "25:00",
            "hrv_schedule__end[0][6]": "25:00",
            "hrv_schedule__end[0][7]": "25:00",
            "hrv_schedule__end[0][8]": "25:00",
            "hrv_schedule__end[1][1]": "25:00",
            "hrv_schedule__end[1][2]": "25:00",
            "hrv_schedule__end[1][3]": "25:00",
            "hrv_schedule__end[1][4]": "25:00",
            "hrv_schedule__end[1][5]": "25:00",
            "hrv_schedule__end[1][6]": "25:00",
            "hrv_schedule__end[1][7]": "25:00",
            "hrv_schedule__end[1][8]": "25:00",
            "hrv_schedule__end[2][1]": "25:00",
            "hrv_schedule__end[2][2]": "25:00",
            "hrv_schedule__end[2][3]": "25:00",
            "hrv_schedule__end[2][4]": "25:00",
            "hrv_schedule__end[2][5]": "25:00",
            "hrv_schedule__end[2][6]": "25:00",
            "hrv_schedule__end[2][7]": "25:00",
            "hrv_schedule__end[2][8]": "25:00",
            "hrv_schedule__end[3][1]": "25:00",
            "hrv_schedule__end[3][2]": "25:00",
            "hrv_schedule__end[3][3]": "25:00",
            "hrv_schedule__end[3][4]": "25:00",
            "hrv_schedule__end[3][5]": "25:00",
            "hrv_schedule__end[3][6]": "25:00",
            "hrv_schedule__end[3][7]": "25:00",
            "hrv_schedule__end[3][8]": "25:00",
            "hrv_schedule__end[4][1]": "17:20",
            "hrv_schedule__end[4][2]": "25:00",
            "hrv_schedule__end[4][3]": "25:00",
            "hrv_schedule__end[4][4]": "25:00",
            "hrv_schedule__end[4][5]": "25:00",
            "hrv_schedule__end[4][6]": "25:00",
            "hrv_schedule__end[4][7]": "25:00",
            "hrv_schedule__end[4][8]": "25:00",
            "hrv_schedule__end[5][1]": "25:00",
            "hrv_schedule__end[5][2]": "25:00",
            "hrv_schedule__end[5][3]": "25:00",
            "hrv_schedule__end[5][4]": "25:00",
            "hrv_schedule__end[5][5]": "25:00",
            "hrv_schedule__end[5][6]": "25:00",
            "hrv_schedule__end[5][7]": "25:00",
            "hrv_schedule__end[5][8]": "25:00",
            "hrv_schedule__end[6][1]": "25:00",
            "hrv_schedule__end[6][2]": "25:00",
            "hrv_schedule__end[6][3]": "25:00",
            "hrv_schedule__end[6][4]": "25:00",
            "hrv_schedule__end[6][5]": "25:00",
            "hrv_schedule__end[6][6]": "25:00",
            "hrv_schedule__end[6][7]": "25:00",
            "hrv_schedule__end[6][8]": "25:00",
            "hrv_schedule__flow[4][1]": 200,
            "hrv_schedule__start[0][1]": "25:00",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__start[0][3]": "25:00",
            "hrv_schedule__start[0][4]": "25:00",
            "hrv_schedule__start[0][5]": "25:00",
            "hrv_schedule__start[0][6]": "25:00",
            "hrv_schedule__start[0][7]": "25:00",
            "hrv_schedule__start[0][8]": "25:00",
            "hrv_schedule__start[1][1]": "25:00",
            "hrv_schedule__start[1][2]": "25:00",
            "hrv_schedule__start[1][3]": "25:00",
            "hrv_schedule__start[1][4]": "25:00",
            "hrv_schedule__start[1][5]": "25:00",
            "hrv_schedule__start[1][6]": "25:00",
            "hrv_schedule__start[1][7]": "25:00",
            "hrv_schedule__start[1][8]": "25:00",
            "hrv_schedule__start[2][1]": "25:00",
            "hrv_schedule__start[2][2]": "25:00",
            "hrv_schedule__start[2][3]": "25:00",
            "hrv_schedule__start[2][4]": "25:00",
            "hrv_schedule__start[2][5]": "25:00",
            "hrv_schedule__start[2][6]": "25:00",
            "hrv_schedule__start[2][7]": "25:00",
            "hrv_schedule__start[2][8]": "25:00",
            "hrv_schedule__start[3][1]": "25:00",
            "hrv_schedule__start[3][2]": "25:00",
            "hrv_schedule__start[3][3]": "25:00",
            "hrv_schedule__start[3][4]": "25:00",
            "hrv_schedule__start[3][5]": "25:00",
            "hrv_schedule__start[3][6]": "25:00",
            "hrv_schedule__start[3][7]": "25:00",
            "hrv_schedule__start[3][8]": "25:00",
            "hrv_schedule__start[4][1]": "17:15",
            "hrv_schedule__start[4][2]": "25:00",
            "hrv_schedule__start[4][3]": "25:00",
            "hrv_schedule__start[4][4]": "25:00",
            "hrv_schedule__start[4][5]": "25:00",
            "hrv_schedule__start[4][6]": "25:00",
            "hrv_schedule__start[4][7]": "25:00",
            "hrv_schedule__start[4][8]": "25:00",
            "hrv_schedule__start[5][1]": "25:00",
            "hrv_schedule__start[5][2]": "25:00",
            "hrv_schedule__start[5][3]": "25:00",
            "hrv_schedule__start[5][4]": "25:00",
            "hrv_schedule__start[5][5]": "25:00",
            "hrv_schedule__start[5][6]": "25:00",
            "hrv_schedule__start[5][7]": "25:00",
            "hrv_schedule__start[5][8]": "25:00",
            "hrv_schedule__start[6][1]": "25:00",
            "hrv_schedule__start[6][2]": "25:00",
            "hrv_schedule__start[6][3]": "25:00",
            "hrv_schedule__start[6][4]": "25:00",
            "hrv_schedule__start[6][5]": "25:00",
            "hrv_schedule__start[6][6]": "25:00",
            "hrv_schedule__start[6][7]": "25:00",
            "hrv_schedule__start[6][8]": "25:00",
            "hrv_schedule__temp[4][1]": 21.11111111111111,
            iaq_sensor: "CO2_2000",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 130,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: "null"
          }
        },
        changes: {
          after: {
            _downlinked: ["set_operating_mode"],
            set_operating_mode: 2,
            wireless_dcv_enable: null
          },
          before: {
            _downlinked: [],
            set_operating_mode: "DCV",
            wireless_dcv_enable: null
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
      knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
      name: "HRV CM 499e"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7U4EWPTZM&Signature=t0KOsj92lITQusSZZeFn%2FrSXeHI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQDsXw0vMDGti6zJHTbt6Zqq4KVHp50cDH7YXZQUIqTEAQIgFKMm9vNSDcX4v6ahBzfcdFlxJl4Q5zHeDNollSArf08quwUIrP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MDgzMjg3NDU3MjciDCh4WPE48JNArUkKaiqPBUoyxTd3AuFCBR0rLu4%2FaaZnk0GMNHveFG4Bo%2BRyd67H1HDGDtTYFEchlBdsVW2kS58GSQy9QGrKQeF9HiOunuxkSDEz%2FV4tJpg850aQKhjUpiasxAG8rV9pN9DOYHszbYizVuebhY1hSu2yt02CJ2sC4xk3jmHdDK0HgZswl77RCORzS6tOQpSUOcw4rlznxEihet4JhfBstxLR3o4fId3KGcs6ad7G4WY2BJdQZ%2BVbP18wKn4n28SsQyvL8uUeVshTKNBO3070GB2J7uLHhZ%2FvBYc1Z%2FDO%2FP7p1FMU5ajKujFKwHcu2O2vjkeoPm%2FRYhdyB5Ygmge%2F%2BaIg9Op2kIIN%2FlU53RSrnlKKhXz4XG1Kis6fFCe5%2BVlVvsECYWafTw5lWUlmL3oZHrl9IN4mzMsqqrcGenOaZa5jS4sUpB11nd%2BQa8qJ%2FRc6e5%2BgAxKOi869QQkRjtRouzhueDIMF5EO3G8ELNy2YgUMeLVbMuILsJBB65%2BKoeafh1vs%2BUZexVJWhIUzMNSgr2chqIHjZ2mC%2FuFhuEinMLV7gLNVNWigB95PNgvf%2BebaUjF50CK2ll6oIEau3egYgTndD8PqcOuo4xN40bvTGFOA1LN1E2yZB9nNSuEYt0s5Q5tHSl4n%2BmUQJe6FuagCmtCp2JnqxDbxnHgbHCxtPJbOBZXEqE8tzt632rp%2Bky%2Foq1AIsZ6WivZRsBMsmYFZAFBDplkbsEwPtxPqYl4vLqhAH7Nkef%2F0QbzmAkmv3azFi2baz5jmXf%2B2wK%2FRqdJIZEq%2F7D%2BIT0E%2FozMv2cdYTZ8et4cpOm4DgZxnPAcAMxpILOXD%2BQJfEfyxqmVeoljcnY8ost3KRas3OVsJNaWjlrCQJBHlUcow39rutQY6sQH1lJKOglFHxmQksBpIw526oGQvCdcMiTCBcq%2FHuMyrQLueH915dtWiXFzl4DPyb5RptcqTyTNb4h1ViR4BJ2%2FkBUfOAyi95cV4lGkxrrT0yP8NCRYLzZROSInOtxzd%2BmD%2BcZroamAzv8C%2B88zk19Q6uKT6mfHk5wN9w3iPY4FeWTn9aFaB8VSUFK9uCRtXexPRDRFG3GF0uyGLox5sPjjBUUODhT%2BabvIKJZI5X%2B6iRbo%3D&Expires=1723586672"
      },
      position: 2
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "5d8e1a02-6fd7-4339-8436-41064cab7fb2",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-08-10T00:17:57.580986+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
          settings: {
            powered: true
          }
        },
        changes: {
          after: {
            _downlinked: ["power_on"],
            power_on: 1
          },
          before: {
            _downlinked: [],
            power_on: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "dd22145a-1e9f-455f-8630-9dfc6c43e05f",
      knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
      name: "HRV CM 499e"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7U4EWPTZM&Signature=t0KOsj92lITQusSZZeFn%2FrSXeHI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQDsXw0vMDGti6zJHTbt6Zqq4KVHp50cDH7YXZQUIqTEAQIgFKMm9vNSDcX4v6ahBzfcdFlxJl4Q5zHeDNollSArf08quwUIrP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgw1MDgzMjg3NDU3MjciDCh4WPE48JNArUkKaiqPBUoyxTd3AuFCBR0rLu4%2FaaZnk0GMNHveFG4Bo%2BRyd67H1HDGDtTYFEchlBdsVW2kS58GSQy9QGrKQeF9HiOunuxkSDEz%2FV4tJpg850aQKhjUpiasxAG8rV9pN9DOYHszbYizVuebhY1hSu2yt02CJ2sC4xk3jmHdDK0HgZswl77RCORzS6tOQpSUOcw4rlznxEihet4JhfBstxLR3o4fId3KGcs6ad7G4WY2BJdQZ%2BVbP18wKn4n28SsQyvL8uUeVshTKNBO3070GB2J7uLHhZ%2FvBYc1Z%2FDO%2FP7p1FMU5ajKujFKwHcu2O2vjkeoPm%2FRYhdyB5Ygmge%2F%2BaIg9Op2kIIN%2FlU53RSrnlKKhXz4XG1Kis6fFCe5%2BVlVvsECYWafTw5lWUlmL3oZHrl9IN4mzMsqqrcGenOaZa5jS4sUpB11nd%2BQa8qJ%2FRc6e5%2BgAxKOi869QQkRjtRouzhueDIMF5EO3G8ELNy2YgUMeLVbMuILsJBB65%2BKoeafh1vs%2BUZexVJWhIUzMNSgr2chqIHjZ2mC%2FuFhuEinMLV7gLNVNWigB95PNgvf%2BebaUjF50CK2ll6oIEau3egYgTndD8PqcOuo4xN40bvTGFOA1LN1E2yZB9nNSuEYt0s5Q5tHSl4n%2BmUQJe6FuagCmtCp2JnqxDbxnHgbHCxtPJbOBZXEqE8tzt632rp%2Bky%2Foq1AIsZ6WivZRsBMsmYFZAFBDplkbsEwPtxPqYl4vLqhAH7Nkef%2F0QbzmAkmv3azFi2baz5jmXf%2B2wK%2FRqdJIZEq%2F7D%2BIT0E%2FozMv2cdYTZ8et4cpOm4DgZxnPAcAMxpILOXD%2BQJfEfyxqmVeoljcnY8ost3KRas3OVsJNaWjlrCQJBHlUcow39rutQY6sQH1lJKOglFHxmQksBpIw526oGQvCdcMiTCBcq%2FHuMyrQLueH915dtWiXFzl4DPyb5RptcqTyTNb4h1ViR4BJ2%2FkBUfOAyi95cV4lGkxrrT0yP8NCRYLzZROSInOtxzd%2BmD%2BcZroamAzv8C%2B88zk19Q6uKT6mfHk5wN9w3iPY4FeWTn9aFaB8VSUFK9uCRtXexPRDRFG3GF0uyGLox5sPjjBUUODhT%2BabvIKJZI5X%2B6iRbo%3D&Expires=1723586672"
      },
      position: 2
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "1fe8ac82-de80-4d01-b0e9-d2a743bebef2",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-07T18:18:59.091460+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
          settings: {
            "temperature_control_mode__point[2]": 22.77777777777778
          }
        },
        changes: {
          after: {
            _downlinked: ["temperature_control_mode__point[2]"],
            "temperature_control_mode__point[2]": 22.77777777777778
          },
          before: {
            _downlinked: [],
            "temperature_control_mode__point[2]": 23.8
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "324247af-40c3-49fa-8784-701a8f31e95a",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
      knownAssetUuid: "3c67bdfe-1a0a-44e8-8c5c-82885deb682a",
      name: "RTU Tstat"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=g8hkevqbCfz7H4ed2gJyA4oJhzY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 2
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "822b4819-b21e-49f8-bc7e-237009810b7e",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-07T18:17:11.038673+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
          settings: {
            "temperature_control_mode__point[2]": 22.77777777777778
          }
        },
        changes: {
          after: {
            _downlinked: ["temperature_control_mode__point[2]"],
            "temperature_control_mode__point[2]": 22.77777777777778
          },
          before: {
            _downlinked: [],
            "temperature_control_mode__point[2]": 23.8
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "324247af-40c3-49fa-8784-701a8f31e95a",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "324247af-40c3-49fa-8784-701a8f31e95a",
      knownAssetUuid: "3c67bdfe-1a0a-44e8-8c5c-82885deb682a",
      name: "RTU Tstat"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=g8hkevqbCfz7H4ed2gJyA4oJhzY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 2
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "e9cb554d-24c5-4a50-a778-18e40c08f6e3",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:40:55.865968+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[1][1]": "11:58",
            "hrv_schedule__end[2][1]": "12:01",
            "hrv_schedule__flow[0][1]": 202,
            "hrv_schedule__flow[1][1]": 220,
            "hrv_schedule__flow[2][1]": 225,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[1][1]": "9:58",
            "hrv_schedule__start[2][1]": "6:01",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            "hrv_schedule__temp[1][1]": 23.333333333333332,
            "hrv_schedule__temp[2][1]": 22.22222222222222,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: ["target_temp"],
            target_temp: 70,
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            target_temp: 20.0,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "c794337b-dfa2-49c6-8037-9dd1767c8048",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:40:22.388041+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            target_temp: 20
          }
        },
        changes: {
          after: {
            _downlinked: ["target_temp"],
            target_temp: 68
          },
          before: {
            _downlinked: [],
            target_temp: 22.22222222222222
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "25898e37-aa71-4600-96d6-891c95bea169",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:39:22.550764+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            target_temp: 22.22222222222222
          }
        },
        changes: {
          after: {
            _downlinked: ["target_temp"],
            target_temp: 72
          },
          before: {
            _downlinked: [],
            target_temp: 21.11111111111111
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "f8b87fb8-2903-41ed-a9f8-5380a75e06c8",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:33:41.192453+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
          settings: {
            iu_airflow_status: "AUTO",
            iu_antifreeze_status: false,
            iu_auto_temp_lower_limit: 18.333333333333332,
            iu_auto_temp_upper_limit: 26.666666666666668,
            iu_cooldry_temp_lower_limit: 18.333333333333332,
            iu_cooldry_temp_upper_limit: 26.666666666666668,
            iu_econ_status: false,
            iu_heat_temp_lower_limit: 18.333333333333332,
            iu_heat_temp_upper_limit: 26.666666666666668,
            iu_horiz_air_direction_status: 0,
            iu_mode_status: "COOL",
            iu_rc_prevent_all: false,
            iu_rc_prevent_filter_reset: false,
            iu_rc_prevent_op_mode_set: false,
            iu_rc_prevent_room_temp_set: false,
            iu_rc_prevent_start_set: false,
            iu_rc_prevent_start_stop_set: false,
            iu_rc_prevent_timer_set: false,
            iu_set_temp_status: 23.333333333333332,
            iu_vert_air_direction_status: "POS1"
          }
        },
        changes: {
          after: {
            _downlinked: ["set_iu_temp_value", "set_iu_temp_change"],
            set_iu_temp_change: 1,
            set_iu_temp_value: 92
          },
          before: {
            _downlinked: [],
            set_iu_temp_change: 1,
            set_iu_temp_value: 22.22222222222222
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
      knownAssetUuid: "322cf051-1a6f-43e6-b887-a75babf1c25c",
      name: "VRF - Kitchen"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=g8hkevqbCfz7H4ed2gJyA4oJhzY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 2
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "2c0905fb-3d5d-4d63-8c2f-cca2caa5a82e",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:33:00.276920+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
          settings: {
            iu_set_temp_status: 22.22222222222222
          }
        },
        changes: {
          after: {
            _downlinked: ["set_iu_temp_value", "set_iu_temp_change"],
            set_iu_temp_change: 1,
            set_iu_temp_value: 88
          },
          before: {
            _downlinked: [],
            set_iu_temp_change: 1,
            set_iu_temp_value: 23.333333333333332
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
      knownAssetUuid: "322cf051-1a6f-43e6-b887-a75babf1c25c",
      name: "VRF - Kitchen"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=g8hkevqbCfz7H4ed2gJyA4oJhzY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 2
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "162ff492-5240-47b1-aea0-3d06a775e3f4",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "e20f4d39-a662-4da5-9261-c73a63de05cb",
    assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
    ownerUuid: "95c6f7ff-8af1-4fe9-912b-4587bbe92617",
    stamp: DateTime.fromISO("2024-07-05T23:32:14.615288+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
          settings: {
            iu_set_temp_status: 23.333333333333332
          }
        },
        changes: {
          after: {
            _downlinked: ["set_iu_temp_value", "set_iu_temp_change"],
            set_iu_temp_change: 1,
            set_iu_temp_value: 92
          },
          before: {
            _downlinked: [],
            set_iu_temp_change: 1,
            set_iu_temp_value: 22.22222222222222
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "2c67ffc2-efd3-402d-b26b-31a5b233560e",
      knownAssetUuid: "322cf051-1a6f-43e6-b887-a75babf1c25c",
      name: "VRF - Kitchen"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Main Floor",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/e20f4d39-a662-4da5-9261-c73a63de05cb?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=g8hkevqbCfz7H4ed2gJyA4oJhzY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 2
    },
    user: {
      emailAddress: "jivenator@gmail.com",
      contactInfo: {
        emailAddress: "jivenator@gmail.com",
        name: "Jason Tiberius"
      }
    }
  },
  {
    eventUuid: "5edd7ac4-5fd1-4953-88bd-9b2dfe2f959f",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-07-02T22:47:04.004035+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            powered: true
          }
        },
        changes: {
          after: {
            _downlinked: ["powered"],
            powered: true
          },
          before: {
            _downlinked: [],
            powered: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "5db87fdb-61fc-4e3e-9183-d8b5fe4c08c2",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "df23c6f5-49c0-43d5-b3ca-8c08afb0ab26",
    stamp: DateTime.fromISO("2024-07-02T22:43:47.824372+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[1][1]": "11:58",
            "hrv_schedule__end[2][1]": "12:01",
            "hrv_schedule__flow[0][1]": 202,
            "hrv_schedule__flow[1][1]": 220,
            "hrv_schedule__flow[2][1]": 225,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[1][1]": "9:58",
            "hrv_schedule__start[2][1]": "6:01",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            "hrv_schedule__temp[1][1]": 23.333333333333332,
            "hrv_schedule__temp[2][1]": 22.22222222222222,
            iaq_sensor: "None",
            operating_mode: "CAV",
            rtc: "2024-07-02T15:43:00",
            schedule_enabled: true,
            set_dst_enabled: false,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: ["rtc"],
            rtc: "2024-07-02T15:43:00",
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            rtc: "2024-07-02T15:11:07",
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "katelynn@ventacity.com",
      contactInfo: {
        emailAddress: "katelynn@ventacity.com",
        name: "katelynn@ventacity.com"
      }
    }
  },
  {
    eventUuid: "d39d36eb-c381-48f3-b084-bb15eefe0b99",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "2f52899a-e89c-49cb-87e3-c4e7ba40c632",
    stamp: DateTime.fromISO("2024-07-02T19:01:43.154614+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[1][1]": "11:58",
            "hrv_schedule__end[2][1]": "12:01",
            "hrv_schedule__flow[0][1]": 202,
            "hrv_schedule__flow[1][1]": 220,
            "hrv_schedule__flow[2][1]": 225,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[1][1]": "9:58",
            "hrv_schedule__start[2][1]": "06:01",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            "hrv_schedule__temp[1][1]": 23.333333333333332,
            "hrv_schedule__temp[2][1]": 22.22222222222222,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: ["hrv_schedule__start[2][1]", "hrv_schedule__end[2][1]", "hrv_schedule__flow[2][1]"],
            "hrv_schedule__end[2][1]": "12:01",
            "hrv_schedule__flow[2][1]": 225,
            "hrv_schedule__start[2][1]": "06:01",
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            "hrv_schedule__end[2][1]": "25:00",
            "hrv_schedule__flow[2][1]": 215,
            "hrv_schedule__start[2][1]": "25:00",
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "barry.paul+staging@gmail.com",
      contactInfo: {
        emailAddress: "bpaul@planet-hood.com",
        name: "Barry Paul"
      }
    }
  },
  {
    eventUuid: "9908cf86-03d1-4bc2-ad9a-d223c2963458",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "2f52899a-e89c-49cb-87e3-c4e7ba40c632",
    stamp: DateTime.fromISO("2024-07-02T18:58:41.309257+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[1][1]": "11:58",
            "hrv_schedule__flow[0][1]": 202,
            "hrv_schedule__flow[1][1]": 220,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[1][1]": "09:58",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            "hrv_schedule__temp[1][1]": 23.333333333333332,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: [
              "hrv_schedule__start[1][1]",
              "hrv_schedule__end[1][1]",
              "hrv_schedule__flow[1][1]",
              "hrv_schedule__temp[1][1]"
            ],
            "hrv_schedule__end[1][1]": "11:58",
            "hrv_schedule__flow[1][1]": 220,
            "hrv_schedule__start[1][1]": "09:58",
            "hrv_schedule__temp[1][1]": 23.333333333333332,
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            "hrv_schedule__end[1][1]": "25:00",
            "hrv_schedule__flow[1][1]": 225,
            "hrv_schedule__start[1][1]": "25:00",
            "hrv_schedule__temp[1][1]": 22.22222222222222,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "barry.paul+staging@gmail.com",
      contactInfo: {
        emailAddress: "bpaul@planet-hood.com",
        name: "Barry Paul"
      }
    }
  },
  {
    eventUuid: "f0865bfb-2125-433a-8fce-de92786c605f",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "2f52899a-e89c-49cb-87e3-c4e7ba40c632",
    stamp: DateTime.fromISO("2024-07-02T18:55:15.461387+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__flow[0][1]": 202,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: ["hrv_schedule__flow[0][1]"],
            "hrv_schedule__flow[0][1]": 202,
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            "hrv_schedule__flow[0][1]": 200,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "barry.paul+staging@gmail.com",
      contactInfo: {
        emailAddress: "bpaul@planet-hood.com",
        name: "Barry Paul"
      }
    }
  },
  {
    eventUuid: "cb8111b9-1d94-4cad-9f51-4acc452d4d7a",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:53:00.389853+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            _downlinked: ["hrv_schedule__start[0][1]"],
            "hrv_schedule__start[0][1]": "11:11",
            wireless_dcv_enable: false
          },
          before: {
            _downlinked: [],
            "hrv_schedule__start[0][1]": "11:10",
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "7b710267-25db-49bb-aabb-e839bbda80f5",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:37:42.443258+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:10",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            wireless_dcv_enable: false
          },
          before: {
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "4f004339-aaf8-4115-bd45-ea67fc993b52",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:36:06.522193+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            target_airflow: 330,
            wireless_dcv_enable: false
          },
          before: {
            target_airflow: 331,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "ea90aca8-9bba-494b-9f53-16666d6ff2a9",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:35:43.900060+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:10",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            target_airflow: 330,
            wireless_dcv_enable: false
          },
          before: {
            target_airflow: 331,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "ac1de734-d000-4712-a2e3-e457e8a083d2",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:33:22.723201+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:10",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 331,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            target_airflow: 331,
            wireless_dcv_enable: false
          },
          before: {
            target_airflow: 330,
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "813eeb50-933e-4a82-b891-84e7b804a9f0",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:33:03.657469+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:10",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            wireless_dcv_enable: false
          },
          before: {
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  },
  {
    eventUuid: "c87befb4-5290-4456-9e16-44c9ce8b5857",
    accountUuid: "e091f35b-1c54-438a-b58a-df32e8032aae",
    buildingUuid: "1c870468-5d9c-4a41-8e0b-f4227ac87a92",
    floorUuid: "d242d7a2-1dfc-42f8-a5a8-2360f41b788b",
    assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
    ownerUuid: "1d0af957-4168-4c50-8912-7f0a7e74082c",
    stamp: DateTime.fromISO("2024-07-02T18:29:37.584844+00:00"),
    eventType: "mutation",
    eventData: {
      mutation: {
        args: {
          assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
          settings: {
            boost_flow: 100,
            boost_timer: 1,
            economizer_airflow: 50,
            economizer_enabled: true,
            economizer_end: "2023-09-21T17:48:00",
            economizer_start: "2023-05-01T17:45:00",
            economizer_temperature: 18.333333333333332,
            flow_offset: 0,
            "hrv_schedule__end[0][1]": "15:10",
            "hrv_schedule__end[0][2]": "25:00",
            "hrv_schedule__flow[0][1]": 200,
            "hrv_schedule__start[0][1]": "11:11",
            "hrv_schedule__start[0][2]": "25:00",
            "hrv_schedule__temp[0][1]": 19.444444444444443,
            iaq_sensor: "None",
            operating_mode: "CAV",
            schedule_enabled: true,
            target_airflow: 330,
            target_temp: 21.11111111111111,
            temp_sensor_location: "SUPPLY",
            unoccupied_flow: 20,
            unoccupied_timer: 30,
            wireless_dcv_enable: false
          }
        },
        changes: {
          after: {
            wireless_dcv_enable: false
          },
          before: {
            wireless_dcv_enable: false
          }
        },
        name: "updateDeviceSettings",
        object_type: "ubx_asset",
        object_uuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
        operation: "send_settings"
      }
    },
    asset: {
      assetUuid: "fcf3bfd5-9ce8-49ba-9aad-58a6774a5342",
      knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
      name: "HRV"
    },
    building: {
      name: "HRV & VRF Test Lab",
      timeZone: "America/Vancouver"
    },
    floor: {
      name: "Rooftop",
      floorplan: {
        url: "https://ubx-staging-user-uploads.s3.amazonaws.com/d242d7a2-1dfc-42f8-a5a8-2360f41b788b?AWSAccessKeyId=ASIAXMWWBDL7YZQJTHCF&Signature=GBjFw2D9KAD0y7Vu2Y0G7i1oRO8%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLXdlc3QtMiJHMEUCIGH8mgBplzNgHiRUbX6S6lYnh5ZXHIiRAgkityxgJJFyAiEAn1EgFb0wdExkXrZiRCLE9th7V3jPMpM11VmEt4ql%2F8gqsgUIHBAEGgw1MDgzMjg3NDU3MjciDNbYk3T9SZerpQHaiCqPBeYU4C0mbp%2FqwhB%2B1%2FPLMZNAEl9Hvw7SeaLiAmpIqHB36i48quZM%2BsfZHkYl1AyU0aqtsyl%2FRCe50EQLS3tRQIBc2NxiNV%2FC9Wckh9jFgBSnIn1NXfx15yoUsBNbulj0c7J%2B8MZT%2B3mzFEhLbk1s25NVhMCMomiQaE2D%2BMw8%2FVSZZd46HWvcDZfsB7IcEFhzR71zeT37TnA%2FFdrz0PYDeOex8llpf7EIxD0rhagtRUm4eO8FBiIsKUU1gtrcybBZmfyctCBmzjD4cHAL%2B4u3nsJjizz6pSaWq3%2Bj05bSjnWXEWGyZLRGiWfxXG6EIOQg4SNTbj3ksnAq7UADGJ2TvtztfuXpnFiBAdCxcEoTdNK2Q8sBVY3PUfSFqkjNi%2BJkRHKqVe%2FQAhTjVpiEhZ2c2HFOmJsWkba69kGpHCA1UQgfg4nPsK0hEZ5IOL7Tqw8K7Ky%2Fp%2FGc4ViSzW4USILy2yxAw9SHGJRUfSc3pOYql7KUeq7NqlseLs%2BxeQdvTApi5S9tK3TliQb7gQ5rv5S8L91kqOl4NZwXV3b8H2LOln4zHb53JtLjraerIzyFEyfsh2FXuj%2BcW4BME97knO6hMMKnhm336x5xCFUYmBupVHAfnhN%2By3cJqDP7Yb8Qq1KpniP3AVFosC4JhrrcVbXC1tWRx9DtDFKbXSRQPKXW6HHAwN20tuR8O2wa2ngSJdVyW8QBC2g5KUkR2aRCQD6LWzcjHNX0RfB1y96dGnFShvqzcwgXAIOZcw3AhZmjBlcGr%2BVC3ZvJ4ikvzDQpcmcs3c5O3NuOanqNi6TPmWpL1H8IzHg8Eafnaq99ubPKAPnJhXImTeOLeS7TKhyuBb1pyOh5bMOx4AN0fGjwC8GpHm4w2OSwtAY6sQF%2F0yP22FUol%2B2m1rGd7NCOSnRd6d55EJZPZ2UykbKi%2Fa8uwWIzdO1XATnfY0m34WmqPl3%2Fd1Ty%2FU74CFVdg5pap%2BxSDo4d9j%2BMdgA%2BEfTNUa009H9eghQ41aifDlblyPPfqiCN1YHrgit%2Fh0PZ2M2vP1h6lC1Ks9j1%2BR6BtEmg%2BgCH0FkD9s3juuHXJuHvY3G56ZcWsNf%2FyHD2ZTB1rg8DMuFwzVHoTnJNGtHNqb%2FoWhA%3D&Expires=1720475508"
      },
      position: 1
    },
    user: {
      emailAddress: "goldcray+stagingubx@gmail.com",
      contactInfo: {
        emailAddress: "goldcray+stagingubx@gmail.com",
        name: "goldcray+stagingubx@gmail.com"
      }
    }
  }
];
