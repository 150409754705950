/* eslint-disable no-alert, no-console */

import { isNil } from "lodash";

const LOG_METHODS = ["log", "debug", "info", "warn", "error", "table"] as const;

function filterConsole(excludePatterns: (string | RegExp)[]): void {
  const shouldFilter = (message: any) => {
    const messageStr = stringify(message);

    for (const pattern of excludePatterns) {
      if (typeof pattern === "string") {
        if (messageStr.includes(pattern)) {
          return true;
        }
      } else if (pattern.test(messageStr)) {
        return true;
      }
    }

    return false;
  };

  for (const method of LOG_METHODS) {
    const originalMethod = console[method];

    console[method] = (...args) => {
      if (shouldFilter(args)) return;
      originalMethod(...args);
    };
  }
}

function stringify(arg: any): string {
  if (isNil(arg)) {
    return "";
  } else if (typeof arg === "string") {
    return arg;
  } else if (Array.isArray(arg)) {
    return arg.map(stringify).join(" ");
  } else if (typeof arg === "object") {
    return Object.keys(arg).join(" ");
  } else {
    return (arg ?? "").toString();
  }
}

filterConsole([
  // shaka-player debug logs (called from Apryse Video)
  "EmeEncryptionSchemePolyfill: Waiting to detect",
  "McEncryptionSchemePolyfill: Waiting to detect",
  // Temporary warning in VueUse#useFullscreen (fixed in next release)
  "[Vue warn]: onMounted is called when there is no active component instance"
]);
