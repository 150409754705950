import Vue, { isReactive } from "vue";
import { isPlainObject, get, has, set, isArray } from "lodash";
import { FieldDescriptor, FieldDescriptorObject, IndexedField, IndexedFieldMap } from "@/types";

export function getIndexedField<T>(collection: IndexedFieldMap<T>, descriptor: FieldDescriptor): T | undefined {
  const { name, params } = toDescriptorObject(descriptor);
  const path = [name, ...params];
  return get(collection, path) as T | undefined;
}

export function setIndexedField<T>(collection: IndexedFieldMap<T>, descriptor: FieldDescriptor, field: T): void {
  const { name, params } = toDescriptorObject(descriptor);
  const path = [name, ...params];
  setPath(collection, path, field);
}

function fieldIndices<T>(collection: IndexedFieldMap<T>, name: string): number[][] {
  const allIndices: number[][] = [];
  fieldIndicesAtLevel(collection[name], [], allIndices);
  return allIndices;
}

function fieldIndicesAtLevel<T>(field: IndexedField<T>, currentIndices: number[], allIndices: number[][]): void {
  if (!isArray(field)) {
    if (currentIndices.length > 0) allIndices.push(currentIndices);
    return;
  }

  field.forEach((subField, i) => {
    const newIndices = [...currentIndices, i];
    fieldIndicesAtLevel(subField, newIndices, allIndices);
  });
}

export function expandDescriptorToIndexes<T>(
  collection: IndexedFieldMap<T>,
  descriptor: FieldDescriptor,
  dimensions: number
): FieldDescriptorObject[] {
  if (isFieldDescriptorObject(descriptor)) return [descriptor];
  if (dimensions === 0) return [toDescriptorObject(descriptor)];

  const allIndices = fieldIndices(collection, descriptor);
  return allIndices.map(indices => ({ name: descriptor, params: indices }));
}

function isFieldDescriptorObject(descriptor: FieldDescriptor): descriptor is FieldDescriptorObject {
  return isPlainObject(descriptor) && has(descriptor, "name");
}

export function toDescriptorObject(descriptor: FieldDescriptor): FieldDescriptorObject {
  if (isFieldDescriptorObject(descriptor)) return descriptor;
  return {
    name: descriptor,
    params: []
  };
}

export function descriptorMatchesDimensions(descriptorObj: FieldDescriptorObject, dimensions: number): boolean {
  return dimensions === descriptorObj.params.length;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setPath(model: Record<string, any>, path: (string | number)[], value: any): void {
  if (!isReactive(model)) {
    set(model, path, value);
    return;
  }

  let cursor: any = model;

  path.forEach((segment, index) => {
    const nextSegment = path[index + 1] as typeof segment | undefined;
    if (nextSegment === undefined) {
      Vue.set(cursor, segment, value);
    } else if (cursor[segment] === undefined) {
      Vue.set(cursor, segment, typeof nextSegment === "number" ? [] : {});
    }
    cursor = cursor[segment];
  });
}
