import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (buildingGroupUuid: string, name: string): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($buildingGroupUuid: ID!, $name: String!) {
          updateBuildingGroup(buildingGroupUuid: $buildingGroupUuid, name: $name)
        }
      `,
      variables: {
        buildingGroupUuid: buildingGroupUuid,
        name: name
      }
    })
    .then(response => {
      return response.data.updateBuildingGroup;
    });
}
