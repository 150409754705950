const state = {
  visible: false,
  color: "",
  textKey: "",
  timeout: 5000
};

const mutations = {
  showSnackbar(state: Record<any, any>, { color, key, timeout }: Record<any, any>): void {
    state.visible = true;
    state.color = color;
    state.textKey = key;
    state.timeout = timeout;
  },

  hideSnackbar(state: Record<any, any>): void {
    state.visible = false;
  }
};

export default {
  state,
  mutations
};
