import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import store from "@/store";

export default function (emailAddress: string, role = "editor"): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($accountUuid: ID!, $emailAddress: String!, $role: UserRole!) {
        createUserInvitation(accountUuid: $accountUuid, emailAddress: $emailAddress, role: $role)
      }
    `,
    variables: {
      accountUuid: store.getters.accountUuid,
      emailAddress: emailAddress,
      role: role.toUpperCase()
    }
  });
}
