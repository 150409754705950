// From: https://developer.auth0.com/resources/guides/spa/vue/basic-authentication/v2-javascript

import Vue from "vue";
import { createAuth0Client, Auth0Client, GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import store from "@/store";

/** Define a default action to perform after authentication */
// eslint-disable-next-line
const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance: any;

export const getInstance = (): any => instance;

interface Auth0InstanceData {
  auth0Client: Auth0Client | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  user: Record<string, any>;
  error: any;
}

// eslint-disable-next-line
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...pluginOptions
}): any => {
  if (instance) return instance;

  instance = new Vue({
    // eslint-disable-next-line
    name: "Auth0",
    data(): Auth0InstanceData {
      return {
        auth0Client: null,
        isLoading: true,
        isAuthenticated: false,
        user: {},
        error: null
      };
    },
    methods: {
      loginWithRedirect(options: any) {
        return this.auth0Client?.loginWithRedirect(options);
      },

      logout(options: any) {
        return this.auth0Client?.logout(options);
      },

      getAccessTokenSilently(o: any): Promise<GetTokenSilentlyVerboseResponse> | undefined {
        return this.auth0Client?.getTokenSilently(o);
      }
    },

    async created() {
      this.auth0Client = await createAuth0Client({
        ...pluginOptions,
        domain: pluginOptions.domain,
        clientId: pluginOptions.clientId,
        authorizationParams: {
          audience: pluginOptions.audience,
          // eslint-disable-next-line
          redirect_uri: redirectUri
        }
      });

      try {
        const search = window.location.search;

        if ((search.includes("code=") || search.includes("error=")) && search.includes("state=")) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          onRedirectCallback(appState);
        }
      } catch (error) {
        this.error = error;
      } finally {
        const user = await this.auth0Client.getUser();

        this.user = user ?? {};
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.isLoading = false;

        this.getAccessTokenSilently().then((accessToken: string) => {
          store.commit("setAccessToken", `Bearer ${accessToken}`);
        });
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  // eslint-disable-next-line
  install(vue: any, options: any): void {
    vue.prototype.$auth0 = useAuth0(options);
  }
};
