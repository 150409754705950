import { computed, ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { AggEnergyConsumption, MaybeRef } from "@/types";

export interface UseAggEnergyConsumptionOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseAggEnergyConsumptionResult {
  aggEnergyConsumptionByTime: Ref<AggEnergyConsumption | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useAggEnergyConsumption(
  buildingUuid: MaybeRef<string | null>,
  startDate: MaybeRef<DateTime>,
  endDate: MaybeRef<DateTime>,
  { enabled = true }: UseAggEnergyConsumptionOptions = {}
): UseAggEnergyConsumptionResult {
  const aggEnergyConsumptionByTime = ref<AggEnergyConsumption | undefined>();
  const loadingError = ref(false);
  const startDateUtc = computed(() => unref(startDate).setZone("utc"));
  const endDateUtc = computed(() => unref(endDate).setZone("utc"));

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query aggEnergyQuery($buildingUuid: ID!, $startDate: DateTime!, $endDate: DateTime!) {
        aggEnergyConsumptionByTime(buildingUuid: $buildingUuid, startDate: $startDate, endDate: $endDate) {
          kwhTotal
          kwhAvgDaily
          points {
            time
            kwhActual
            kwhEst
          }
          energyDevices {
            name
            kwhTotal
          }
        }
      }
    `,
    {
      buildingUuid,
      startDate: startDateUtc,
      endDate: endDateUtc,
      timestamp: DateTime.local() // Ignored, but ensures that variables are never the same
    },
    () => ({
      enabled: unref(enabled) && unref(buildingUuid) !== null && unref(startDate) !== null && unref(endDate) !== null,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      aggEnergyConsumptionByTime.value = queryResult.data.aggEnergyConsumptionByTime;
    }
  });

  return { aggEnergyConsumptionByTime, loadingError, refetch, loading };
}
