import { computed, ref, watch, ComputedRef, Ref, unref } from "vue";
import { DateTime, Duration } from "luxon";
import { formatDuration } from "@/utils/date";
import { MaybeRef, TimestampField } from "@/types";
import store from "@/store";

export interface UsePropertyTimestampsResult {
  timestamp: ComputedRef<DateTime | null>;
  formattedTimestamp: ComputedRef<string | null>;
  relativeTimestamp: Ref<Duration | undefined>;
  formattedRelativeTimestamp: ComputedRef<string | null>;
}

export default function usePropertyTimestamps(property: MaybeRef<TimestampField>): UsePropertyTimestampsResult {
  const timestamp = computed(() => {
    const { timestamp } = unref(property);
    const zone = store.getters.timeZone;
    return timestamp ? DateTime.fromISO(timestamp).setZone(zone) : null;
  });

  const relativeTimestamp = ref<Duration>();
  let interval: ReturnType<typeof setInterval> | undefined;

  function updateRelativeTimestamp(): void {
    relativeTimestamp.value = timestamp.value?.diffNow();
  }

  watch(
    timestamp,
    () => {
      updateRelativeTimestamp();

      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }

      if (timestamp.value) interval = setInterval(updateRelativeTimestamp, 10000);
    },
    { immediate: true }
  );

  const formattedTimestamp = computed(() => {
    if (!timestamp.value) return null;
    return timestamp.value.toFormat("yyyy-MM-dd - hh:mm a");
  });

  const formattedRelativeTimestamp = computed(() => {
    if (!relativeTimestamp.value) return null;
    return formatDuration(relativeTimestamp.value, ["days", "hours", "minutes"]);
  });

  return {
    timestamp,
    formattedTimestamp,
    relativeTimestamp,
    formattedRelativeTimestamp
  };
}
