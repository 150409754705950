import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { Asset } from "@/types";

export default function (asset: Asset): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($assetUuid: ID!) {
          deleteAsset(assetUuid: $assetUuid)
        }
      `,
      variables: { assetUuid: asset.assetUuid }
    })
    .then(response => {
      return response.data.deleteAsset;
    });
}
